import React, { FormEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import { Button, Heading, Tile } from "@dtpk-cc/components";
import Input from "../../../../elements/new-design/Input";
import { trackClick } from "../../../../helpers/reactTracking";
import { onlyNumber } from "../../../../helpers/NumericCalculation";
import SwitchVariants from "../../../../elements/new-design/SwitchVariants";
import { IAddon } from "../../../../core/presenter/PencilSelling/AddonsPresenter";
import { ICustomAddon, ICustomAddonFormData } from "../IAddonsSelection";
import { Group } from "../../../../core/entities/Product/IProduct";
import { PaymentTypes } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";

import * as styles from "./addons-custom-device-form.module.scss";

type AddonsCustomDeviceFormProps = {
  trackingContext: string;
  addProduct: (
    product: IAddon | ICustomAddon,
    paymentType: PaymentTypes
  ) => void;
  addonGroup: Group;
  customClass?: string;
  customAddonFormData: ICustomAddonFormData;
  customAddonContractPeriod: number;
};

const DEFAULT_FORM_STATE = {
  name: "",
  price: "",
  priceType: PaymentTypes.MONTHLY,
};

const REQUIRED_MSG_PLACEHOLDER = "Dieses Feld ist erforderlich";

const CustomAddonForm = ({
  trackingContext,
  addProduct,
  addonGroup,
  customAddonFormData,
  customAddonContractPeriod,
  customClass = "",
}: AddonsCustomDeviceFormProps) => {
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

  const formStateChangeHandler = (key: string, value: string) => {
    setFormState({
      ...formState,
      [key]: value,
    });
  };

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const priceValue = parseFloat(formState.price.replace(",", "."));

    const customDevice: ICustomAddon = {
      category: null,
      description: null,
      nameAlternative: null,
      productGroup: null,
      type: addonGroup,
      name: formState.name,
      price: {
        monthly:
          formState.priceType === PaymentTypes.MONTHLY ? priceValue : null,
        once: formState.priceType === PaymentTypes.ONCE ? priceValue : null,
      },
      key: uuidv4(),
      contractPeriod: customAddonContractPeriod,
      isCustom: true,
    };

    addProduct(customDevice, formState.priceType);
  };

  return (
    <Tile customClass={`${styles.wrapper} ${customClass}`} variant="shadow">
      <Heading
        tag="p"
        variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
        customClass="m-b-20"
      >
        {customAddonFormData.title}
      </Heading>
      <form onSubmit={formSubmitHandler} className={styles.form}>
        <Input
          wrapperCustomClass="m-b-16"
          value={formState.name}
          placeholder={customAddonFormData.namePlaceHolder}
          id="name"
          name="name"
          aria-label="name"
          onClick={() =>
            trackClick(customAddonFormData.trackClickValue, trackingContext)
          }
          onChange={(event) =>
            formStateChangeHandler("name", event.target.value)
          }
          required
          requiredMessage={REQUIRED_MSG_PLACEHOLDER}
        />
        <div className={styles.priceWrapper}>
          <Input
            wrapperCustomClass="m-r-12"
            value={formState.price}
            placeholder="Preis"
            id="preis"
            name="preis"
            aria-label="preis"
            onClick={() =>
              trackClick(customAddonFormData.trackClickValue, trackingContext)
            }
            onKeyPress={onlyNumber}
            onChange={(event) =>
              formStateChangeHandler("price", event.target.value)
            }
            required
            requiredMessage={REQUIRED_MSG_PLACEHOLDER}
          />
          <SwitchVariants
            containerCustomClass="m-t-14"
            onClick={() => {
              formStateChangeHandler(
                "priceType",
                formState.priceType === PaymentTypes.MONTHLY
                  ? PaymentTypes.ONCE
                  : PaymentTypes.MONTHLY
              );
            }}
            isChecked={formState.priceType === PaymentTypes.ONCE}
            leftVariantTitle="Monatlich"
            rightVariantTitle="Einmalig"
          />
        </div>
        <Button
          type="submit"
          tabIndex={0}
          customClass={`${styles.submitBtn} default-button blue`}
          variants={Button.Variant.bare}
        >
          Speichern
        </Button>
      </form>
    </Tile>
  );
};

export default observer(CustomAddonForm);
