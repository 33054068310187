import React from "react";
import { ITariff } from "../../../../core/entities/Product/Tariff/ITariff";
import TariffSelectionWrapper from "./TariffSelectionWrapper";
import TariffEntries, { GetTariffPriceProp } from "./TariffEntries";
import { ICartConfigurationSettings } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { IStepTrackingContext } from "../../../../views/PencilSelling/IPencilSelling";
import { getDefaultTariffPrice } from "./TariffEntries/helpers/default-tariff-prices";

type TariffSelectionProps = {
  tariffs: ITariff[];
  activeTariff: ITariff;
  isBenefitActive: boolean;
  settings: ICartConfigurationSettings;
  handleTariffChange: (tariff: ITariff, activeTariff: ITariff) => void;
  getTariffFeature: (tariff: ITariff) => {
    tariffFeature: string;
    tariffFeatureUnit: string;
    tariffExtraFeature?: string;
    tariffExtraFeatureUnit?: string;
  };
  getProductDescription?: (tariff: ITariff) => JSX.Element | null;
  trackingContext: IStepTrackingContext;
  getTariffPrice?: GetTariffPriceProp;
};

const TariffSelection: React.FC<TariffSelectionProps> = ({
  tariffs,
  activeTariff,
  settings,
  isBenefitActive,
  handleTariffChange,
  getTariffFeature,
  children,
  getProductDescription = () => null,
  trackingContext,
  getTariffPrice = getDefaultTariffPrice,
}) => (
  <TariffSelectionWrapper tariffName={activeTariff?.name}>
    {children}
    <TariffEntries
      trackingContext={trackingContext}
      tariffs={tariffs}
      activeTariff={activeTariff}
      handleTariffChange={handleTariffChange}
      isBenefitActive={isBenefitActive}
      getTariffFeature={getTariffFeature}
      loyaltyBonusValue={
        "loyaltyBonusValue" in settings ? settings.loyaltyBonusValue : null
      }
      getProductDescription={getProductDescription}
      getTariffPrice={getTariffPrice}
    />
  </TariffSelectionWrapper>
);

export default TariffSelection;
