import React from "react";
import { Button, CopyText, Heading, Price, Icon } from "@dtpk-cc/components";
import * as radioMarkStyles from "elements/new-design/RadioButtonMain/RadioButtonInput/radio-button.module.scss";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";
import LoyaltyIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/loyalty.svg";
import DisneyIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/streaming-services/disney_plus_icon.png";
import NetflixIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/streaming-services/netflix_icon.png";
import RtlIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/streaming-services/rtl_icon.png";

import * as styles from "./tariff-entry.module.scss";
import { Volume } from "../../../../../core/entities/Product/Card/ICard";

type TariffEntryProps = {
  tariffName: string;
  streamingServices: string[];
  isActive: boolean;
  price: number;
  isYoung: boolean | null;
  isVario: boolean | null;
  subtitle: {
    tariffFeature: string;
    tariffFeatureUnit: string;
    tariffExtraFeature?: string;
    tariffExtraFeatureUnit?: string;
  };
  isHybrid: boolean;
  onClick: () => void;
  priceWithLoyaltyBonus?: number | null;
  description?: JSX.Element | null;
  legacyPrice?: number;
  disabled?: boolean;
  customClass?: string;
  monthlyPriceBeforeFrameworkDiscount?: number | null;
  relatedAddonsAreExist: boolean;
};

const streamingServicesMap: {
  [key: string]: {
    image: string;
    name: string;
  };
} = {
  netflix_standard: {
    image: NetflixIcon as string,
    name: "Netflix Standard",
  },
  netflix_with_ads: {
    image: NetflixIcon as string,
    name: "Netflix mit Werbung",
  },
  disney_standard: {
    image: DisneyIcon as string,
    name: "Disney+ Standard",
  },
  disney_with_ads: {
    image: DisneyIcon as string,
    name: "Disney+ mit Werbung",
  },
  rtl_premium: {
    image: RtlIcon as string,
    name: "RTL+ Premium",
  },
  rtl_max_without_music: {
    image: RtlIcon as string,
    name: "RTL+ Max ohne Musik",
  },
};

const TariffEntry = ({
  priceWithLoyaltyBonus,
  streamingServices,
  tariffName,
  isActive,
  price,
  isYoung = false,
  isVario = false,
  subtitle,
  description = null,
  isHybrid,
  onClick,
  legacyPrice,
  disabled = false,
  customClass = "",
  monthlyPriceBeforeFrameworkDiscount,
  relatedAddonsAreExist,
}: TariffEntryProps) => {
  let scaledOriginalPrice =
    !!legacyPrice && legacyPrice !== price ? legacyPrice : null;

  scaledOriginalPrice =
    monthlyPriceBeforeFrameworkDiscount || scaledOriginalPrice;

  const tariffCurrentDataVolume = [
    subtitle.tariffFeature,
    subtitle.tariffFeatureUnit,
  ].join(" ");

  return (
    <Button
      disabled={disabled}
      tabIndex={0}
      customClass={`${styles.tariffEntry} ${
        isActive ? styles.active : ""
      } ${customClass}`}
      variants={Button.Variant.bare}
      onClick={onClick}
    >
      {/* Main container */}
      <div
        className={`${styles.mainContainer} ${
          priceWithLoyaltyBonus ? styles.withLoyaltyBonus : ""
        }`}
      >
        {/* Top */}
        <div className={styles.topWrapper}>
          {/* Header */}
          <div className={styles.header}>
            <CopyText customClass={styles.title}>
              {tariffName}
              {isYoung ? " Young" : ""}
              {isVario ? " Vario" : ""}
            </CopyText>
            <span
              className={`${radioMarkStyles.radioMark} ${styles.radioMark}`}
            />
          </div>
          {/* Size */}
          <div className={styles.dataVolumeWrapper}>
            {!!subtitle.tariffFeature && (
              <Heading
                tag="p"
                customClass={styles.dataVolume}
                variants={[Heading.Variant.hero, Heading.Variant.display]}
              >
                {subtitle.tariffFeature}
              </Heading>
            )}
            {!!subtitle.tariffFeatureUnit && (
              <Heading
                tag="p"
                variants={Heading.Variant.quaternary}
                customClass={styles.dataVolumeUnit}
              >
                {subtitle.tariffFeatureUnit}
                {isHybrid && " + 300 MBit/s (LTE)"}
              </Heading>
            )}
            {!!subtitle?.tariffExtraFeature && (
              <Heading
                tag="p"
                customClass={`${styles.dataVolumeExtra} m-l-8 m-b-4`}
                variants={[Heading.Variant.tertiary]}
              >
                +{subtitle.tariffExtraFeature}
              </Heading>
            )}
            {!!subtitle?.tariffExtraFeatureUnit && (
              <CopyText
                variants={CopyText.Variant.magenta}
                customClass="m-b-4 m-l-4"
              >
                {subtitle.tariffExtraFeatureUnit}
                {isHybrid && " + 300 MBit/s (LTE)"}
              </CopyText>
            )}
          </div>
          {!!description && (
            <div className={styles.infoItem}>{description}</div>
          )}
          {streamingServices.map((streamingServiceItemKey) => (
            <div key={streamingServiceItemKey} className={styles.infoItem}>
              <img
                src={streamingServicesMap[streamingServiceItemKey].image}
                alt={streamingServicesMap[streamingServiceItemKey].name}
                className={`${styles.streamingServicesIcon} m-r-6`}
              />
              <CopyText variants={CopyText.Variant.highlight}>
                {streamingServicesMap[streamingServiceItemKey].name}
              </CopyText>
            </div>
          ))}
          {relatedAddonsAreExist &&
            // No need to display (50% mehr Datenvolumen) if data volume is unlimited
            tariffCurrentDataVolume !== Volume.INFINITY_VALUE && (
              <>
                <div className={styles.infoItem}>
                  <Icon
                    wrapperProps={{ className: "m-r-6" }}
                    icon={ActionAddDefault}
                    customClass="defaultIcon"
                  />
                  <CopyText variants={CopyText.Variant.highlight}>
                    Streaming-Option
                  </CopyText>
                </div>
                <div className={styles.infoItem}>
                  <CopyText
                    customClass={styles.subInfoItemText}
                    variants={CopyText.Variant.highlight}
                  >
                    (50% mehr Datenvolumen)
                  </CopyText>
                </div>
              </>
            )}
        </div>
        {/* Bottom */}
        <div className={styles.bottomWrapper}>
          {/* Prices wrapper */}
          <div
            className={`${styles.priceWrapper} ${
              priceWithLoyaltyBonus ? styles.withLoyaltyBonus : ""
            }`}
          >
            {scaledOriginalPrice && (
              <Price
                value={scaledOriginalPrice}
                variants={[Price.Variant.scaled, Price.Variant.large]}
              />
            )}
            <div
              className={`${styles.mainPriceWrapper} ${
                priceWithLoyaltyBonus ? styles.withLoyaltyBonus : ""
              }`}
            >
              <Price
                variants={Price.Variant.brand}
                value={price}
                suffix="mtl."
              />
            </div>
          </div>
        </div>
      </div>
      {/* Loyalty bonus */}
      {priceWithLoyaltyBonus && (
        <div className={styles.loyaltyBonus}>
          <LoyaltyIcon className={styles.loyaltyBonusIcon} />
          <div>
            <CopyText customClass={styles.loyaltyBonusPriceTitle}>
              mit Treuebonus
            </CopyText>
            <div className={styles.loyaltyBonusPriceWrapper}>
              <Price
                variants={Price.Variant.brand}
                value={priceWithLoyaltyBonus}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                prefix={<>&Oslash;</>}
              />
            </div>
          </div>
        </div>
      )}
    </Button>
  );
};

export default TariffEntry;
