import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores/PrivateClient";
import { useTariffSelection } from "../../../../../hooks/PencilSelling/useTariffSelection";
import { ILandlineSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { PortfolioKeys } from "../../../../../core/entities/Product/IProduct";
import { useTariffSelectionData } from "../../../../../hooks/PencilSelling/useTariffSelectionData";
import { businessCaseOptions } from "../../../../../core/entities/Product/Tariff/ITariff";
import TariffMainWrapper from "../../../../../components/new-design/Tariff/TariffMainWrapper";
import LandlineTariffConfiguration from "../../../../../components/new-design/Tariff/TariffConfiguration/LandlineTariffConfiguration";
import TariffSelection from "../../../../../components/new-design/Tariff/TariffSelection";
import TariffSelectionHeader from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionHeader";
import TariffSelectionBenefit from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionBenefit";
import AddonsResetWarningModal from "../../../../../components/new-design/Tariff/AddonsResetWarningModal";
import { useLandlineTariffSelection } from "../../../../../hooks/PencilSelling/useLandlineTariffSelection";

const TRACKING_CONTEXT = {
  main: "wunschtarif-privat",
  portfolio: "festnetz",
};

const LandlineTariffSelection = () => {
  const {
    productsStore,
    configurationsStore,
    offerStore,
    productsRepositoryStore,
  } = useStores();

  const { tariffs } = productsStore;

  const {
    settings: landlineSettings,
    isBenefitActive,
    tariffInCart,
    activeTariff: landlineTariff,
    setActiveTariff: setLandlineTariff,
    selectedPromotions: selectedLandlinePromotions,
    addToCart,
    addPromotionsHandler,
    warningModalCallback,
    warningModalIsOpen,
    setWarningModalIsOpen,
    customPromotions,
  } = useTariffSelection<ILandlineSettings>(
    PortfolioKeys.LANDLINE,
    tariffs[PortfolioKeys.LANDLINE],
    configurationsStore,
    productsStore,
    offerStore.getBaseOffer()
  );
  const { businessCase } = landlineSettings;

  const {
    tariffWorlds,
    levels,
    activeLevelExists,
    filteredTariffs,
    activeLevelIndex,
    setActiveLevelIndex,
    businessCaseName,
    activeTariffWorld,
  } = useTariffSelectionData({
    businessCase,
    isLandline: true,
    settings: landlineSettings,
    tariffs: tariffs[PortfolioKeys.LANDLINE],
    businessCaseData: businessCaseOptions,
  });

  const {
    toggleLandlineBenefit,
    handleLandlineTariffChange,
    handleLandlineSettingsChange,
    getLandlineTariffFeature,
  } = useLandlineTariffSelection({
    landlineSettings,
    businessCase,
    configurationsStore,
    landlineTariff,
    setLandlineTariff,
    isBenefitActive,
    addToCart,
    selectedLandlinePromotions,
    tariffs: tariffs[PortfolioKeys.LANDLINE],
  });

  const applyLandlineFilter = (filterSettings: Partial<ILandlineSettings>) => {
    const updatedSettings = {
      ...landlineSettings,
      ...filterSettings,
      businessCase,
    };
    handleLandlineSettingsChange(null, null, updatedSettings);
  };

  return (
    <TariffMainWrapper>
      <LandlineTariffConfiguration
        withMultipleOnceMonthlyPromotions
        withCustomPromotions
        customPromotions={customPromotions[PortfolioKeys.LANDLINE]}
        offerStore={offerStore.getBaseOffer()}
        productsRepositoryStore={productsRepositoryStore}
        activeTariffInCart={tariffInCart}
        activeTariff={landlineTariff}
        trackingContext={TRACKING_CONTEXT}
        tariffWorlds={tariffWorlds}
        levels={levels}
        onSettingsChange={handleLandlineSettingsChange}
        setActiveLevelIndex={setActiveLevelIndex}
        activeLevelIndex={activeLevelIndex}
        activeLevelExists={activeLevelExists}
        settings={landlineSettings}
        selectedPromotions={selectedLandlinePromotions}
        addPromotionsHandler={addPromotionsHandler}
        businessCase={businessCase}
        businessCaseName={businessCaseName}
        configurationsStore={configurationsStore}
        applyLandlineFilter={applyLandlineFilter}
      />
      <TariffSelection
        tariffs={filteredTariffs}
        activeTariff={landlineTariff}
        isBenefitActive={isBenefitActive}
        settings={landlineSettings}
        handleTariffChange={handleLandlineTariffChange}
        getTariffFeature={getLandlineTariffFeature}
        trackingContext={TRACKING_CONTEXT}
      >
        <TariffSelectionHeader
          checkboxLabelText="Young-Tarife anzeigen"
          settings={landlineSettings}
          settingKey="isYoung"
          isChecked={landlineSettings.isYoung}
          onChange={() => {
            handleLandlineSettingsChange("isYoung", !landlineSettings.isYoung);
          }}
          activeTariffWorld={activeTariffWorld}
        />
        <div className="tariff-entries-bonus-wrapper">
          <TariffSelectionBenefit
            withLoyaltyBonus={false}
            isActive={isBenefitActive}
            onClick={() => toggleLandlineBenefit(landlineTariff)}
          />
        </div>
      </TariffSelection>
      <AddonsResetWarningModal
        trackingContext={TRACKING_CONTEXT}
        resetOptionsModalCallback={warningModalCallback}
        setResetOptionsModalIsOpen={setWarningModalIsOpen}
        resetOptionsModalIsOpen={warningModalIsOpen}
      />
    </TariffMainWrapper>
  );
};

export default observer(LandlineTariffSelection);
