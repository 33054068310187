import React, { ElementType, useRef, useState } from "react";
import {
  Button,
  Heading,
  CopyText,
  Icon as IconLpg,
} from "@dtpk-cc/components";
import { observer } from "mobx-react";
import { trackClick } from "helpers/reactTracking";
import { ConfigurationsEntity } from "core/entities/PencilSelling/Configurations/Configurations";
import ActionCloseDefault from "@dtpk-cc/components/dist/icons/Action/Close/Default";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import AddNewIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/config-list/add-new.svg";

import * as styles from "./configuration-flyout.module.scss";
import * as tileStyles from "../navigation-flyout-tile-item.module.scss";
import ConfigurationFlyoutWrapper from "./ConfigurationFlyoutWrapper";
import ConfigurationFlyoutTitle from "./ConfigurationFlyoutTitle";
import ConfigurationFlyoutContainer from "./ConfigurationFlyoutContainer";
import ConfigurationFlyoutItem from "./ConfigurationFlyoutItem";
import CheckboxInput from "../../../../elements/new-design/CheckboxInput";
import { TRACKING_CONTEXT, iconsMap, configurationsNameMap } from "./const";
import { setActiveConfigurationHandler } from "./helpers";
import { getConfigurationName } from "../../../../helpers/GetConfigurationName";

type ConfigurationFlyoutProps = {
  // to pickup right icons and search for appropriate configurations.
  type: ICartConfigurationType;
  hideFlyoutHandler: () => void;
  configurationsStore: ConfigurationsEntity;
  addConfigurationHandler: () => void;
  removeConfigurationHandler: ({
    type,
    configurationKeysToDelete,
  }: {
    type: ICartConfigurationType;
    configurationKeysToDelete: string[];
  }) => void;
};

const ConfigurationFlyout = ({
  type,
  hideFlyoutHandler,
  configurationsStore,
  addConfigurationHandler,
  removeConfigurationHandler,
}: ConfigurationFlyoutProps) => {
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const [configurationKeysToDelete, setConfigurationKeysToDelete] = useState<
    string[]
  >([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const configurations = configurationsStore.getConfigurationsByType(type);

  const toggleConfigurationKeyToDeleteHandler = (
    configuration: ICartConfiguration
  ) => {
    if (configurationKeysToDelete.includes(configuration.key)) {
      setConfigurationKeysToDelete((prev) =>
        prev.filter(
          (configurationKey) => configurationKey !== configuration.key
        )
      );
    } else {
      setConfigurationKeysToDelete((prev) => [...prev, configuration.key]);
    }
    trackClick(`löschen.${type}`, TRACKING_CONTEXT);
  };

  const tileClickHandler = (configuration: ICartConfiguration) => {
    if (isDeleteMode) {
      toggleConfigurationKeyToDeleteHandler(configuration);
      return;
    }
    setActiveConfigurationHandler(
      hideFlyoutHandler,
      configuration,
      configurationsStore,
      TRACKING_CONTEXT,
      type
    );
  };

  const toggleDeleteModeHandler = () => {
    setIsDeleteMode((prev) => !prev);
    setConfigurationKeysToDelete([]);
  };

  const deleteModeMainBtnClickHandler = () => {
    if (isDeleteMode && configurationKeysToDelete.length) {
      removeConfigurationHandler({ type, configurationKeysToDelete });
    }
    toggleDeleteModeHandler();
  };

  const Icon = iconsMap[type] as ElementType;

  return (
    <ConfigurationFlyoutWrapper
      ref={addButtonRef}
      configurations={configurations}
    >
      <div className={`${styles.header}`}>
        <ConfigurationFlyoutTitle
          title={
            isDeleteMode
              ? configurationsNameMap[type].deleteModeTitle
              : configurationsNameMap[type].title
          }
        />
        <div className="m-t-4">
          {isDeleteMode && (
            <Button
              onClick={toggleDeleteModeHandler}
              customClass={`m-r-24 ${styles.deleteModeCloseBtn}`}
              variants={Button.Variant.bare}
            >
              <IconLpg
                size={IconLpg.Size.small}
                wrapperProps={{ className: "m-r-12" }}
                icon={ActionCloseDefault}
              />
              <CopyText variants={CopyText.Variant.highlight}>
                Abbrechen
              </CopyText>
            </Button>
          )}
          <Button
            onClick={deleteModeMainBtnClickHandler}
            variants={Button.Variant.bare}
          >
            <IconLpg
              size={IconLpg.Size.small}
              wrapperProps={{ className: "m-r-12" }}
              icon={ActionRemoveDefault}
            />
            <CopyText variants={CopyText.Variant.highlight}>
              {isDeleteMode ? "Auswahl löschen" : "Auswählen zum Löschen"}
            </CopyText>
          </Button>
        </div>
      </div>
      <ConfigurationFlyoutContainer>
        {configurations.map((configuration, index) => (
          <ConfigurationFlyoutItem
            key={configuration.key}
            onClick={() => tileClickHandler(configuration)}
            isActive={
              configurationsStore.getActiveConfiguration().key ===
              configuration.key
            }
            name={getConfigurationName(configuration, index, type)}
            Icon={Icon}
          >
            {isDeleteMode && (
              <>
                <div className={styles.deleteModeItemOverlay} />
                <div className={styles.deleteModeItemCheckbox}>
                  <CheckboxInput
                    disabled
                    name={configuration.name}
                    id={configuration.key}
                    checked={configurationKeysToDelete.includes(
                      configuration.key
                    )}
                  />
                </div>
              </>
            )}
          </ConfigurationFlyoutItem>
        ))}
        <Button
          disabled={isDeleteMode}
          ref={addButtonRef}
          customClass={`m-12 py-24 px-20 ${tileStyles.navigationFlyoutTileItem} ${styles.addNewButton}`}
          tabIndex={0}
          variants={Button.Variant.bare}
          onClick={() => {
            addConfigurationHandler();
            trackClick(`neue.hinzufügen.${type}`, TRACKING_CONTEXT);
          }}
        >
          <div className={tileStyles.navigationFlyoutTileItemContainer}>
            <AddNewIcon className="m-b-12" />
          </div>
          <Heading
            customClass={tileStyles.navigationFlyoutTileItemText}
            tag="p"
            variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          >
            Hinzufügen
          </Heading>
        </Button>
      </ConfigurationFlyoutContainer>
    </ConfigurationFlyoutWrapper>
  );
};

export default observer(ConfigurationFlyout);
