import React, { useState } from "react";
import { Icon, CopyText, Heading, Button, Modal } from "@dtpk-cc/components";
import {
  AlertInformationDefault,
  ActionSuccessSelected,
} from "@dtpk-cc/components/dist/icons";
import { trackClick } from "../../../../../helpers/reactTracking";
import * as styles from "./configurator-services.module.scss";
import { ServiceCasesItem, ServiceDataItemModalData } from "../../const";

type ConfiguratorServicesProps = {
  serviceCase: ServiceCasesItem;
  trackingContext: string;
};

const ConfiguratorServices = ({
  serviceCase,
  trackingContext,
}: ConfiguratorServicesProps) => {
  const [serviceModalData, setServiceModalData] =
    useState<ServiceDataItemModalData>(null);
  const [serviceModalIsActive, setServiceModalIsActive] = useState(false);

  const toggleServiceModal = () => {
    setServiceModalIsActive((prev) => !prev);
    trackClick("serviceinfo-modal-schließen", trackingContext);
  };

  const updateServiceModal = (modalData) => {
    toggleServiceModal();
    setServiceModalData(modalData);
  };

  return (
    <>
      <div>
        <Heading
          customClass={styles.configuratorServicesTitle}
          tag="h3"
          variants={[Heading.Variant.quaternary, Heading.Variant.display]}
        >
          Services
        </Heading>
        <div className={styles.configuratorServicesList}>
          {serviceCase.serviceList.map((serviceItem) => (
            <div
              key={serviceItem.name}
              // className="configurator-services__list-item configurator-services-item"
              className={`${styles.configuratorServicesListItem} ${styles.configuratorServicesItem}`}
            >
              <Icon
                icon={ActionSuccessSelected}
                size={Icon.Size.small}
                wrapperProps={{
                  className: styles.configuratorServicesItemIconWrapper,
                }}
              />
              <CopyText customClass={styles.configuratorServicesItemName}>
                {serviceItem.name}
              </CopyText>
              <Button
                onClick={() => {
                  trackClick(
                    "dienstbeschreibung-modal-öffnen",
                    trackingContext
                  );
                  updateServiceModal(serviceItem.modalContent);
                }}
                variants={Button.Variant.bare}
                className={styles.configuratorServicesItemInfoButton}
              >
                <Icon icon={AlertInformationDefault} size={Icon.Size.small} />
              </Button>
            </div>
          ))}
        </div>
      </div>
      <Modal zIndex={12} closable={false} isOpen={serviceModalIsActive}>
        <Modal.Section>
          {!!serviceModalData && (
            <>
              <Modal.Heading>{serviceModalData.title}</Modal.Heading>
              {serviceModalData.content.mainText && (
                <CopyText customClass={styles.configuratorServicesModalMainText}>
                  {serviceModalData.content.mainText}
                </CopyText>
              )}
              {!!serviceModalData.content.list && (
                <>
                  {!!serviceModalData.content.list.title && (
                    <Heading
                      customClass={styles.configuratorServicesModalListTitle}
                      tag="h3"
                      variants={[
                        Heading.Variant.quaternary,
                      ]}
                      dangerouslySetInnerHTML={{
                        __html: serviceModalData.content.list.title,
                      }}
                    />
                  )}
                  <ul>
                    {serviceModalData.content.list.items.map((listItem) => (
                      <li
                        key={listItem}
                        className={styles.configuratorServicesModalListItem}
                      >
                        <Icon
                          icon={ActionSuccessSelected}
                          size={Icon.Size.small}
                          wrapperProps={{
                            className:
                              styles.configuratorServicesModalListItemIcon,
                          }}
                        />
                        <CopyText>{listItem}</CopyText>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <Button
                customClass={styles.configuratorServicesModalCloseBtn}
                onClick={toggleServiceModal}
              >
                Schließen
              </Button>
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};

export default ConfiguratorServices;
