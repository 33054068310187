import React from "react";
import { Heading, CopyText, TextLink } from "@dtpk-cc/components";
import ModalWrapper from "../../../../elements/new-design/ModalWrapper";
import { IQRCodeModalData } from "../../../../hooks/useQrCodeModalData";

import * as styles from "./qr-code-modal.module.scss";

type QRCodeModalProps = {
  onClose: () => void;
  qrCodeData: IQRCodeModalData;
};

const QRCodeModal: React.FC<QRCodeModalProps> = ({ qrCodeData, onClose }) => (
  <ModalWrapper
    containerCustomClass={styles.modalContainer}
    onClose={onClose}
    isOpen={qrCodeData.isOpen}
  >
    <Heading variants={[Heading.Variant.tertiary, Heading.Variant.display]}>
      Die persönliche Empfehlung als PDF
    </Heading>
    <CopyText customClass={styles.text}>
      Einfach den QR Code mit dem Smartphone scannen. Anschließend lässt sich
      die persönliche Empfehlung als PDF auf dem Smartphone öffnen und
      speichern.
    </CopyText>
    <img className={styles.qrCodeImg} src={qrCodeData.imageSrc} alt="qr-code" />
    <CopyText customClass={styles.text}>
      Über diesen Link lässt sich das persönliche Angebot ebenfalls als PDF
      öffnen und speichern (7 Tage Gültigkeit).
    </CopyText>
    <TextLink
      target="_blank"
      href={qrCodeData.urlLink}
      customClass={styles.link}
    >
      {qrCodeData.urlLink}
    </TextLink>
  </ModalWrapper>
);

export default QRCodeModal;
