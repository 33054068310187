import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import { IProductsSummary, ProductsMapper } from "core/mapper/ProductsMapper";
import { ProductsMapperBC } from "core/mapper/ProductsMapperBC";
import { ProductsRepository } from "core/repositories/ProductsRepository/ProductsRepository";
import {
  ProductsResponse,
  StreamingAnalysisResponse,
} from "core/repositories/ProductsRepository/ResponseInterface";
import { BaseOffer } from "../core/entities/PencilSelling/BaseOffer/BaseOffer";

interface FetchAndMapProductsParams {
  streamingAnalysisResponse?: StreamingAnalysisResponse;
}

export const fetchAndMapProducts = async (
  productsRepositoryStore: ProductsRepository,
  Mapper: typeof ProductsMapper | typeof ProductsMapperBC,
  customerType: CustomerType,
  productsUrl: string,
  baseOffer: BaseOffer,
  params: FetchAndMapProductsParams = {}
): Promise<{
  products: ProductsResponse;
  summary: IProductsSummary;
}> => {
  // Get products
  const products = await productsRepositoryStore.getProducts(productsUrl);

  // Get custom promotions
  const customPromotionsResponse =
    await productsRepositoryStore.fetchCustomPromotion();

  // Map to Product or Business Client Product
  const mapper = new Mapper({
    productResponse: products,
    customPromotionsResponse,
    ...params,
  });

  const offerPromotionSections = baseOffer.getCustomPromotions();

  // Set and return everything
  baseOffer.setCustomPromotions({
    ...offerPromotionSections,
    ...mapper.customPromotions,
  });

  return {
    products,
    summary: mapper.productsSummary,
  };
};
