import { makeAutoObservable } from "mobx";
import type { IPromotionSections } from "core/entities/PencilSelling/IPromotionSections";
import { ConfigurationsEntity } from "../Configurations/Configurations";
import { ICartConfigurationType } from "../Configurations/IConfigurations";
import { ICustomerEntity } from "../Customer/ICustomer";
import { Customer } from "../Customer/Customer";
import { initialBusinessCases, initialCustomPromotions } from "./const";
import { IPromotionSectionsKeys } from "../IPromotionSectionsKeys";
import { ICustomPromotion } from "../ICustomPromotion";
import { IBusinessCasesTypeValues } from "../../Product/Tariff/ITariff";
import { IBaseOfferExport } from "./IBaseOffer";

export class BaseOffer {
  constructor(configurationsEntity: ConfigurationsEntity) {
    this.configurationsEntity = configurationsEntity;
    makeAutoObservable(this);
  }

  configurationsEntity: ConfigurationsEntity;

  customer: ICustomerEntity = new Customer();

  validUntil = { date: null };

  profileDetailsInPdfIsDisplayed = true;

  // One business case for all defined configuration with corresponding type
  // Temporary using business cases from Private Clients due to no differences
  businessCases = initialBusinessCases;

  private customPromotions: IPromotionSections = initialCustomPromotions;

  isLoading = false;

  displayConfigurationTotalPrice = true;

  displayOfferTotalPrice = true;

  getDisplayConfigurationTotalPrice(): boolean {
    return this.displayConfigurationTotalPrice;
  }

  getDisplayOfferTotalPrice(): boolean {
    return this.displayOfferTotalPrice;
  }

  setDisplayConfigurationTotalPrice(value: boolean): void {
    this.displayConfigurationTotalPrice = value;
  }

  setDisplayOfferTotalPrice(value: boolean): void {
    this.displayOfferTotalPrice = value;
  }

  setIsLoading: (value: boolean) => void = (value: boolean): void => {
    this.isLoading = value;
  };

  getBusinessCase(type: ICartConfigurationType): IBusinessCasesTypeValues {
    return this.businessCases[type];
  }

  setBusinessCase(
    type: ICartConfigurationType,
    value: IBusinessCasesTypeValues
  ): void {
    this.businessCases[type] = value;
  }

  resetBusinessCases(): void {
    this.businessCases = initialBusinessCases;
  }

  getCustomPromotions(): IPromotionSections {
    return this.customPromotions;
  }

  setCustomPromotions(promotions: IPromotionSections): void {
    this.customPromotions = promotions;
  }

  replaceCustomPromotion(
    promotionSection: IPromotionSectionsKeys,
    promotion: ICustomPromotion
  ): void {
    const customPromotionSection = this.customPromotions[promotionSection];
    this.customPromotions[promotionSection] = customPromotionSection.map(
      (promotionItem) =>
        promotionItem.key === promotion.key ? promotion : promotionItem
    );
  }

  removeCustomPromotion(
    promotionSection: IPromotionSectionsKeys,
    promotionKey: string
  ): void {
    const customPromotionSection = this.customPromotions[promotionSection];
    this.customPromotions[promotionSection] = customPromotionSection.filter(
      (promotion: ICustomPromotion) => promotion.key !== promotionKey
    );
  }

  getValidUntil(): Date {
    return this.validUntil.date as Date;
  }

  setValidUntil(validUntil: Date): void {
    this.validUntil = {
      date: validUntil,
    };
  }

  getProfileDetailsInPdfIsDisplayed(): boolean {
    return this.profileDetailsInPdfIsDisplayed;
  }

  setProfileDetailsInPdfIsDisplayed(isDisplayed: boolean): void {
    this.profileDetailsInPdfIsDisplayed = isDisplayed;
  }

  export(): IBaseOfferExport {
    return {
      customerNote: this.customer.get().notice,
      details: {
        consultingTime: this.customer.get().consultingTime,
        validUntil: this.getValidUntil(),
      },
      cartSummary: {
        [ICartConfigurationType.MOBILE]:
          this.configurationsEntity.getConfigurationsByType(
            ICartConfigurationType.MOBILE
          ),
        [ICartConfigurationType.LANDLINE]:
          this.configurationsEntity.getConfigurationsByType(
            ICartConfigurationType.LANDLINE
          ),
        [ICartConfigurationType.DEVICE]:
          this.configurationsEntity.getConfigurationsByType(
            ICartConfigurationType.DEVICE
          ),
        [ICartConfigurationType.CARD]:
          this.configurationsEntity.getConfigurationsByType(
            ICartConfigurationType.CARD
          ),
      },
      displayConfigurationTotalPrice: this.displayConfigurationTotalPrice,
      displayOfferTotalPrice: this.displayOfferTotalPrice,
    };
  }
}
