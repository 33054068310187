import { ITariff } from "../core/entities/Product/Tariff/ITariff";
import {
  IAdditionalDevices,
  ICartItemDataPromotions,
  PaymentTypes,
} from "../core/entities/PencilSelling/CartItem/ICartItem";
import { PortfolioKeys } from "../core/entities/Product/IProduct";
import { ICartConfigurationSettings } from "../core/entities/PencilSelling/Configurations/IConfigurations";
import { TariffPresenter } from "../core/presenter/PencilSelling/TariffPresenter";

interface IProductDescriptionProps<T> {
  tariff: ITariff;
  isBenefitActive: boolean;
  settings?: T;
  relatedAddonsAreSelected?: boolean;
}

export const getTariffBySize = (
  sizeValue,
  tariffsByPortfolio: ITariff[],
  currentTariff: ITariff,
  isYoung,
  isVario
): ITariff =>
  tariffsByPortfolio.find(
    (portfolioTariff) =>
      portfolioTariff.size === currentTariff?.size &&
      portfolioTariff.level.key === sizeValue &&
      portfolioTariff.tariffWorld.key === currentTariff.tariffWorld.key &&
      portfolioTariff.isYoung === isYoung &&
      portfolioTariff?.isVario === isVario
  ) || null;

export const getTariffDataForCart = (
  tariff: ITariff,
  promotions: ICartItemDataPromotions,
  additionalDevices: IAdditionalDevices[],
  portfolio: PortfolioKeys,
  getProductDescription: <T extends ICartConfigurationSettings>({
    tariff,
    isBenefitActive,
    settings,
  }: IProductDescriptionProps<T>) => { summary: string; text: string },
  settings: ICartConfigurationSettings,
  isBenefitActive: boolean,
  relatedAddonsAreSelected: boolean
) => {
  const cartItem = {
    name: tariff.name,
    price: tariff.price,
    key: tariff.key,
    quantity: 1,
    portfolio,
    group: tariff.type,
    description: getProductDescription({ tariff, isBenefitActive, settings, relatedAddonsAreSelected }),
    paymentType: PaymentTypes.MONTHLY,
    contractPeriod: tariff.contractPeriod,
    promotions,
    additionalDevices,
    isFungible: false,
    tariffWorld: tariff.tariffWorld,
    additionalDescription: tariff.additionalDescription,
    extension: null,
  };

  return cartItem;
};

export const tariffProductDescriptionMap = {
  [PortfolioKeys.MOBILE]: ({
    tariff,
    isBenefitActive,
    relatedAddonsAreSelected,
  }: {
    tariff: ITariff;
    isBenefitActive: boolean;
    relatedAddonsAreSelected: boolean;
  }) => ({
    summary: TariffPresenter.getMobileTariffSummary(
      tariff.description.summary,
      tariff.dataVolume,
      tariff.dataVolumeMagentaOne,
      isBenefitActive,
      tariff.dataVolumeExtra,
      relatedAddonsAreSelected,
      tariff.isYoung
    ),
    text: TariffPresenter.getMobileTariffDescription(
      tariff.description.text,
      tariff.dataVolume,
      tariff.dataVolumeMagentaOne,
      isBenefitActive,
      tariff.dataVolumeExtra,
      relatedAddonsAreSelected,
      tariff.isYoung
    ),
  }),
  [PortfolioKeys.LANDLINE]: <T extends ICartConfigurationSettings>({
    tariff,
    isBenefitActive,
    settings,
  }: IProductDescriptionProps<T>) =>
    "fiber" in settings && settings.fiber
      ? {
          summary: tariff.description.summary_fiber,
          text: TariffPresenter.getLandlineTariffDescription(
            tariff.description.text_fiber,
            isBenefitActive
          ),
        }
      : {
          summary: tariff.description.summary,
          text: TariffPresenter.getLandlineTariffDescription(
            tariff.description.text,
            isBenefitActive
          ),
        },
};

export const getFilteredTariff = (
  tariffs: ITariff[],
  activeTariff: ITariff | null,
  settings: ICartConfigurationSettings,
  isLandline: boolean
): ITariff | null => {
  const presenter = new TariffPresenter(tariffs, settings, isLandline);
  const filteredTariffs = presenter.get();

  return (
    filteredTariffs.find((tariff) => tariff.key === activeTariff?.key) || null
  );
};
