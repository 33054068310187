import { IDescription } from "core/entities/Product/IDescription";
import type { IPrice } from "core/entities/Product/IPrice";
import type { IPromotion } from "../IPromotion";

import type {
  Group,
  ILevel,
  PortfolioKeys,
  ITariffWorld,
} from "../../Product/IProduct";
import { IExtension } from "../../Product/IExtension";
import { ICustomPromotion } from "../ICustomPromotion";

export enum PaymentTypes {
  ONCE = "once",
  MONTHLY = "monthly",
}

export enum Egsp {
  NONE = "none",
  DEFAULT = "default",
  PLUS = "plus",
}

export interface IMultiSim {
  price: number;
  quantity: number;
}

export interface ICardData {
  dataVolumeMagentaOne: string;
  note: string;
  multiSim: IMultiSim;
  size: string;
  level: ILevel;
  dataVolume: string;
  businessCaseKey: string;
}

export interface IAdditionalDevices {
  price: string;
  name: string;
  manufacturer?: string;
}

export type ICartItemDataPromotions = Array<IPromotion | ICustomPromotion>;

export interface ICartItemData {
  portfolio: PortfolioKeys;
  name: string;
  price: IPrice;
  key: string;
  paymentType: PaymentTypes;
  quantity: number;
  group: Group;
  tariffWorld?: ITariffWorld;
  description?: IDescription;
  promotions?: ICartItemDataPromotions;
  additionalDevices?: IAdditionalDevices[];
  isFungible: boolean;
  additionalDescription?: string;
  contractPeriod?: number;
  egsp?: string;
  availableFor?: string[];
  cardData?: ICardData;
  inheritingCard?: boolean;
  extension?: IExtension | null;
  isCustom?: boolean;
}

export interface ICartItem extends ICartItemData {
  setQuantity(quantity: number): void;
  getPrice(): IPrice;
  export(): ICartItemData;
}
