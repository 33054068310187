import React from "react";
import { IConfigurationSummaryItem } from "../ISummary";
import SummaryConfiguration from "../SummaryConfiguration";
import { Group } from "../../../../core/entities/Product/IProduct";
import SummaryConfigurationSectionWrapper from "../SummaryConfigurationSectionWrapper";
import SummaryConfigurationSection from "../SummaryConfigurationSection";
import SummaryConfigurationSectionDescription from "../SummaryConfigurationSectionDescription";
import SummaryConfigurationAddition from "../SummaryConfigurationAddition";
import SummaryConfigurationAddonItem from "../SummaryConfigurationAddonItem";
import SummaryConfigurationTotalPrice from "../SummaryConfigurationTotalPrice";

const SummaryCards = ({
  configurations,
  displayConfigurationTotalPrice,
  customerPricePrefix,
}: {
  configurations: IConfigurationSummaryItem;
  displayConfigurationTotalPrice: boolean;
  customerPricePrefix: string;
}) => (
  <>
    {configurations.configurationItems.map((configurationItem) => (
      <SummaryConfiguration
        key={configurationItem.title}
        title={configurationItem.title}
        amount={configurationItem.amount}
      >
        <div>
          {configurationItem.sections
            .filter(
              (sectionItem) =>
                sectionItem.group === Group.tariff ||
                sectionItem.group === Group.card
            )
            .map((section, index) => (
              <SummaryConfigurationSectionWrapper
                key={`${section.name}${index}`}
                name={section.name}
              >
                {section.items.map(
                  ({ additionalRenderData, ...sectionItem }, innerIndex) => (
                    <SummaryConfigurationSection
                      key={`${additionalRenderData.productBaseName}${innerIndex}`}
                    >
                      <SummaryConfigurationSectionDescription
                        customerPricePrefix={customerPricePrefix}
                        sectionItemDescriptionData={{
                          additionalRenderData,
                          ...sectionItem,
                        }}
                      />
                      {additionalRenderData.itemOptionsAreExist && (
                        <SummaryConfigurationAddition
                          customerPricePrefix={customerPricePrefix}
                          additionItems={sectionItem.additions}
                        />
                      )}
                    </SummaryConfigurationSection>
                  )
                )}
              </SummaryConfigurationSectionWrapper>
            ))}
          <SummaryConfigurationAddonItem
            customerPricePrefix={customerPricePrefix}
            configurationItem={configurationItem}
          />
        </div>
        {displayConfigurationTotalPrice && (
          <SummaryConfigurationTotalPrice
            configurationTotalPrice={configurationItem.totalPrice}
          />
        )}
      </SummaryConfiguration>
    ))}
  </>
);

export default SummaryCards;
