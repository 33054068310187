import React from "react";
import { IConfigurationSummaryItem } from "../ISummary";
import SummaryConfiguration from "../SummaryConfiguration";
import { Group } from "../../../../core/entities/Product/IProduct";
import SummaryConfigurationSectionWrapper from "../SummaryConfigurationSectionWrapper";
import SummaryConfigurationSection from "../SummaryConfigurationSection";
import SummaryConfigurationSectionDescription from "../SummaryConfigurationSectionDescription";
import SummaryConfigurationAddition from "../SummaryConfigurationAddition";
import SummaryConfigurationBenefits from "../SummaryConfigurationBenefits";
import SummaryConfigurationLoyaltyBonus from "../SummaryConfigurationLoyaltyBonus";
import SummaryConfigurationAddonItem from "../SummaryConfigurationAddonItem";
import SummaryConfigurationTotalPrice from "../SummaryConfigurationTotalPrice";
import SummaryHappyHour from "../SummaryHappyHour";

const SummaryMobile = ({
  configurations,
  displayConfigurationTotalPrice,
  customerPricePrefix,
}: {
  configurations: IConfigurationSummaryItem;
  displayConfigurationTotalPrice: boolean;
  customerPricePrefix: string;
}) => (
  <>
    {configurations.configurationItems.map((configurationItem) => (
      <SummaryConfiguration
        key={configurationItem.title}
        title={configurationItem.title}
        amount={configurationItem.amount}
      >
        <div>
          {configurationItem.sections
            .filter((sectionItem) => sectionItem.group === Group.tariff)
            .map((section) => (
              <SummaryConfigurationSectionWrapper
                key={section.name}
                name={section.name}
              >
                {section.items.map(
                  ({ additionalRenderData, ...sectionItem }) => (
                    <SummaryConfigurationSection
                      key={additionalRenderData.productBaseName}
                    >
                      <SummaryConfigurationSectionDescription
                        customerPricePrefix={customerPricePrefix}
                        sectionItemDescriptionData={{
                          additionalRenderData,
                          ...sectionItem,
                        }}
                      />
                      {additionalRenderData.itemOptionsAreExist && (
                        <>
                          <SummaryConfigurationAddition
                            additionItems={sectionItem.additions}
                            customerPricePrefix={customerPricePrefix}
                          />
                          <SummaryConfigurationBenefits
                            customerPricePrefix={customerPricePrefix}
                            benefits={sectionItem.benefits}
                          />
                          <SummaryConfigurationLoyaltyBonus
                            customerPricePrefix={customerPricePrefix}
                            loyaltyBonusData={additionalRenderData.loyaltyBonus}
                          />
                          {sectionItem.happyHourBenefitOptionDescription && (
                            <SummaryHappyHour
                              happyHourBenefitOptionDescription={
                                sectionItem.happyHourBenefitOptionDescription
                              }
                            />
                          )}
                        </>
                      )}
                    </SummaryConfigurationSection>
                  )
                )}
              </SummaryConfigurationSectionWrapper>
            ))}
          <SummaryConfigurationAddonItem
            customerPricePrefix={customerPricePrefix}
            configurationItem={configurationItem}
          />
        </div>
        {displayConfigurationTotalPrice && (
          <SummaryConfigurationTotalPrice
            configurationTotalPrice={configurationItem.totalPrice}
          />
        )}
      </SummaryConfiguration>
    ))}
  </>
);

export default SummaryMobile;
