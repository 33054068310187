import React from "react";
import { observer } from "mobx-react";
import {
  Checkbox,
  CopyText,
  Grid,
  Heading,
  HintBox,
  TextLink,
} from "@dtpk-cc/components";
import LpgContinueSolid from "@dtpk-cc/components/dist/icons/Lpg/ContinueSolid";
import TileContainer from "../../TileContainer";
import { TileContainerVariants } from "../../TileContainer/ITileContainer";
import TileContainerTitle from "../../TileContainer/TileContainerTitle";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { IDistributionData } from "../../../../core/presenter/PencilSelling/OfferSummaryBasePresenter";
import { PAGE_USER_PROFILE } from "../../../../routes/extern-url";

import * as styles from "./offer-finalization-shop-data.module.scss";

type OfferFinalizationShopDataProps = {
  offerStore: BaseOffer;
  distributionData: IDistributionData;
  customClass?: string;
};

const OfferFinalizationShopData = ({
  customClass = "",
  offerStore,
  distributionData,
}: OfferFinalizationShopDataProps) => {
  const toggleProfileDataInPdfIsDisplayed = (isDisplayed: boolean) =>
    offerStore.setProfileDetailsInPdfIsDisplayed(isDisplayed);

  return (
    <TileContainer
      customClass={`dataContainer ${customClass}`}
      variant={TileContainerVariants.outline}
    >
      <TileContainerTitle
        variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
        title="Ihre shop adresse"
      />
      <Grid>
        {(distributionData.distributionPoint ||
          distributionData.distributionLink) && (
          <Grid.Row gutter>
            <Grid.Col>
              <HintBox>
                <>
                  <CopyText customClass={styles.shopDataItem}>
                    {distributionData?.distributionPoint?.name}
                  </CopyText>
                  <CopyText customClass={styles.shopDataItem}>
                    {distributionData?.distributionPoint?.adress}
                  </CopyText>
                  <CopyText customClass={styles.shopDataItem}>
                    {distributionData?.distributionPoint?.pls && (
                      <span>{distributionData?.distributionPoint?.pls}</span>
                    )}
                    {distributionData?.distributionPoint?.location && (
                      <span>
                        {distributionData?.distributionPoint?.location}
                      </span>
                    )}
                  </CopyText>
                  {distributionData?.distributionLink && (
                    <CopyText customClass={styles.shopDataItem}>{distributionData?.distributionLink}</CopyText>
                  )}
                </>
              </HintBox>
            </Grid.Col>
          </Grid.Row>
        )}
        <Grid.Row gutter customClass={styles.changeLink}>
          <Grid.Col s={6} m={12} l={12} xl={24}>
            <TextLink
              customClass={styles.content}
              target="_blank"
              href={`/${PAGE_USER_PROFILE}`}
              variants={TextLink.Variant.highlight}
              iconRight={LpgContinueSolid}
              rightIconProps={{
                style: { position: "absolute", top: 2, left: 2 },
              }}
            >
              Shop Adresse bearbeiten
            </TextLink>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row gutter>
          <Grid.Col s={6} m={12} l={12} xl={24}>
            <Checkbox
              checked={offerStore.getProfileDetailsInPdfIsDisplayed()}
              onChange={toggleProfileDataInPdfIsDisplayed}
              role="checkbox"
            >
              Shop Adresse auf PDF anzeigen
            </Checkbox>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </TileContainer>
  );
};

export default observer(OfferFinalizationShopData);
