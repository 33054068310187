import React from "react";
import { observer } from "mobx-react";

import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import AddonsSelection from "../AddonsSelection";
import { ICartConfigurationType } from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { ConfigurationsEntity } from "../../../core/entities/PencilSelling/Configurations/Configurations";
import { getBusinessCaseName } from "../../../helpers/GetBusinessCaseName";
import { businessCaseOptions } from "../../../core/entities/Product/Tariff/ITariff";
import PageWrapper from "../../../elements/new-design/PageWrapper";
import StepHeader from "../StepHeader";
import PageInner from "../../../elements/new-design/PageWrapper/PageInner";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsEntity } from "../../../core/entities/PencilSelling/Products/ProductsEntity";
import { ICustomAddonFormData } from "../AddonsSelection/IAddonsSelection";

interface IDevices {
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  productsStore: ProductsEntity;
  allowCustomProducts: boolean;
  trackingContext: string;
  subtitle: string;
  customAddonContractPeriod: number;
  customAddonFormData: ICustomAddonFormData;
}

const Devices = ({
  configurationsStore,
  offerStore,
  productsStore,
  allowCustomProducts,
  subtitle,
  trackingContext,
  customAddonContractPeriod,
  customAddonFormData,
}: IDevices) => {
  const devices = productsStore.getDevices();
  const devicesInCart = configurationsStore
    .getActiveConfigurationChildren()
    .getDevices();
  const businessCase = offerStore.getBusinessCase(
    ICartConfigurationType.LANDLINE
  );

  return (
    <PageWrapper>
      <StepHeader title="Geräte" subtitle={subtitle} />
      <PageInner>
        <AddonsSelection
          isFungible
          trackingContext={trackingContext}
          products={devices}
          activeProducts={devicesInCart}
          allowCustomProducts={allowCustomProducts}
          condition={null}
          portfolio={PortfolioKeys.LANDLINE}
          addonGroup={Group.device}
          businessCaseName={getBusinessCaseName(
            businessCase,
            businessCaseOptions
          )}
          configurationsStore={configurationsStore}
          customAddonContractPeriod={customAddonContractPeriod}
          customAddonFormData={customAddonFormData}
        />
      </PageInner>
    </PageWrapper>
  );
};

export default observer(Devices);
