import React from "react";
import { observer } from "mobx-react";
import PageWrapper from "elements/new-design/PageWrapper";
import PageInner from "elements/new-design/PageWrapper/PageInner";

import { useStores } from "stores/BusinessClient";
import { useStepActionHeaderNavMap } from "hooks/PencilSelling/useStepActionHeaderNavMap";
import MobileTariffSelection from "views/PencilSelling/BusinessClient/MobileTariff/MobileTariffSelection";
import StepActionHeader from "components/new-design/StepHeader/StepActionHeader";
import {
  ICartConfigurationType,
  IMobileSettings,
} from "core/entities/PencilSelling/Configurations/IConfigurations";
import MobileTariffAddons from "./MobileTariffAddons";
import { useRemoveConfiguration } from "../../../../hooks/PencilSelling/useRemoveConfiguration";
import { useGetActiveConfigurationDefaultName } from "../../../../hooks/PencilSelling/useGetActiveConfigurationDefaultName";
import { AMOUNT_PICKER_MAX_VALUE } from "../../../../constants/const";

const TRACKING_CONTEXT = "mobilfunk-tarife-geschaeftskunden";

type MobileTariffProps = {
  displayConfigurationFlyout: (flyoutKey: string) => void;
};

const MobileTariff = ({ displayConfigurationFlyout }: MobileTariffProps) => {
  const { configurationsStore, offerStore } = useStores();
  const { removeConfigurationHandler } = useRemoveConfiguration(
    configurationsStore,
    offerStore.getBaseOffer()
  );
  const activeConfiguration = configurationsStore.getActiveConfiguration();
  const { activeTabIndex, getMobileNavMap } = useStepActionHeaderNavMap(
    TRACKING_CONTEXT,
    configurationsStore
  );
  const { activeConfigurationName } = useGetActiveConfigurationDefaultName(
    ICartConfigurationType.MOBILE,
    configurationsStore
  );
  const businessCase = offerStore
    .getBaseOffer()
    .getBusinessCase(ICartConfigurationType.MOBILE);
  const settings =
    configurationsStore.getActiveConfigurationSettings<IMobileSettings>();

  const navTabsMap = [
    <MobileTariffSelection />,
    <MobileTariffAddons
      businessCase={businessCase}
      trackingContext="extras-mobilfunk-geschaeftskunden"
    />,
  ];

  // Disable and reset amount picker in case current configuration has loyaltyBonusValue > 0 or activated benefit,
  // and it's the only configuration with entered loyaltyBonusValue
  const loyaltyBonusIsApplied =
    configurationsStore.activeConfigurationIsWithLoyaltyBonus() &&
    settings?.loyaltyBonusValue > 0;
  // and it's the only configuration of certain type with activated benefit
  const benefitIsApplied =
    configurationsStore.activeConfigurationIsWithActiveBenefit(
      ICartConfigurationType.MOBILE
    ) && configurationsStore.getActiveConfigurationBenefitIsActive();

  return (
    <PageWrapper>
      <StepActionHeader
        limit={benefitIsApplied ? 1 : AMOUNT_PICKER_MAX_VALUE}
        configurationsStore={configurationsStore}
        trackingContext={TRACKING_CONTEXT}
        title={activeConfigurationName}
        subtitle="Alles zum mobilen Surfen und Telefonieren"
        navMap={getMobileNavMap()}
        activeTabIndex={activeTabIndex}
        disabledPlus={
          loyaltyBonusIsApplied ||
          benefitIsApplied ||
          configurationsStore.getActiveConfigurationAmount() >=
            AMOUNT_PICKER_MAX_VALUE
        }
        removeConfigurationHandler={() =>
          removeConfigurationHandler({
            type: ICartConfigurationType.MOBILE,
            configurationKeysToDelete: [activeConfiguration.key],
          })
        }
        offerStore={offerStore.getBaseOffer()}
        displayConfigurationFlyout={displayConfigurationFlyout}
      />
      <PageInner>{navTabsMap[activeTabIndex]}</PageInner>
    </PageWrapper>
  );
};

export default observer(MobileTariff);
