import React, { useState } from "react";
import AddonsSelectionModalWrapper from "../AddonsSelectionModalWrapper";
import ModalSelectionDescription from "../../../../elements/new-design/ModalSelectionDescription";
import { ICartItemData } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { IPromotion } from "../../../../core/entities/PencilSelling/IPromotion";
import PromotionSelectionItem from "../../../../elements/new-design/ExtraSelection/PromotionSelectionItem";
import { trackClick } from "../../../../helpers/reactTracking";

type AddonsPromotionSelectionModalProps = {
  activeProduct: ICartItemData;
  promotions: IPromotion[];
  isOpen: boolean;
  onSubmit: (promotions: IPromotion[]) => void;
  onCancel: () => void;
  businessCaseName: string;
  trackingContext: string;
  customClass?: string;
};

const AddonsPromotionSelectionModal = ({
  activeProduct,
  promotions,
  isOpen,
  onSubmit,
  onCancel,
  businessCaseName,
  customClass = "",
  trackingContext = "",
}: AddonsPromotionSelectionModalProps) => {
  const [activePromotions, setActivePromotions] = useState(
    activeProduct.promotions.map((item) => item.key)
  );

  const handlePromotionClick = (promotionKey: string) => {
    const currentPromotions = activePromotions.includes(promotionKey)
      ? activePromotions.filter((key) => key !== promotionKey)
      : [...activePromotions, promotionKey];

    setActivePromotions(currentPromotions);
  };

  const handleSubmit = () => {
    const updatedPromotions = promotions.filter((promotion) =>
      activePromotions.includes(promotion.key)
    );
    onSubmit(updatedPromotions);
  };

  return (
    <AddonsSelectionModalWrapper
      title="Aktionsangebote"
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      customClass={customClass}
    >
      <ModalSelectionDescription
        businessCaseName={businessCaseName}
        productName={activeProduct.name}
      />
      {promotions.map((promotion) => (
        <PromotionSelectionItem
          key={promotion.key}
          id={promotion.key}
          pricing={promotion.discount}
          productPrice={activeProduct?.price.monthly}
          name={promotion?.description || promotion.name}
          checked={activePromotions.includes(promotion.key)}
          onChange={() => {
            handlePromotionClick(promotion.key);
            trackClick(`${promotion.name}`, trackingContext);
          }}
          title={promotion?.description || promotion.name}
          subtitle={promotion.additionalDescription}
        />
      ))}
    </AddonsSelectionModalWrapper>
  );
};

export default AddonsPromotionSelectionModal;
