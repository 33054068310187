import { hot } from "react-hot-loader/root";
import { setConfig } from "react-hot-loader";

import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
// TODO Temporary
// import { trackPencilSellingPage } from "../../../helpers/reactTracking";
// Styles from LPG Should be imported in root component, due to webpacker styles-loader workflow
import "@dtpk-cc/components/dist/fonts-local.css";
import "@dtpk-cc/components/dist/components/Asterisk/Asterisk.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/EqualHeight/EqualHeight.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/RadioButton/RadioButton.css";
import "@dtpk-cc/components/dist/components/Grid/Grid.css";
import "@dtpk-cc/components/dist/components/Flex/Flex.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Input/Input.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "@dtpk-cc/components/dist/components/List/List.css";
import "@dtpk-cc/components/dist/components/Modal/Modal.css";
import "@dtpk-cc/components/dist/components/Price/Price.css";
import "@dtpk-cc/components/dist/components/Tabs/Tabs.css";
import "@dtpk-cc/components/dist/components/Badge/Badge.css";
import "@dtpk-cc/components/dist/components/Tile/Tile.css";
import "@dtpk-cc/components/dist/components/Dropdown/Dropdown.css";
import "@dtpk-cc/components/dist/components/TextLink/TextLink.css";
import "@dtpk-cc/components/dist/components/ToggleButton/ToggleButton.css";
import "@dtpk-cc/components/dist/components/Indicator/Indicator.css";
import "@dtpk-cc/components/dist/components/Tooltip/Tooltip.css";
import "@dtpk-cc/components/dist/components/StepIndicator/StepIndicator.css";
import "@dtpk-cc/components/dist/components/HintBox/HintBox.css";
import "@dtpk-cc/components/dist/components/Checkbox/Checkbox.css";
import LoadingSpinner from "elements/LoadingSpinner";
import { useStores } from "stores/BusinessClient/index";
// TODO Temporary hidden
import {
  NAV_TITLE_BASE_DATA,
  NAV_TITLE_DEVICES,
  NAV_TITLE_LANDLINE_TARIFF,
  NAV_TITLE_MOBILE_TARIFF,
  NAV_TITLE_OFFER_FINALISATION,
  NAV_TITLE_SECONDARY_CARDS,
  NAV_TITLE_SUMMARY,
} from "components/new-design/Navigation/const";
import { ICartConfigurationType } from "core/entities/PencilSelling/Configurations/IConfigurations";
import ConfigurationFlyout from "components/new-design/Navigation/ConfigurationFlyout";
import {
  IFrameworkContractSelectState,
  IPencilSellingProps,
} from "../IPencilSelling";
import MobileTariff from "./MobileTariff";
import LandlineTariff from "./LandlineTariff";
import SecondaryCards from "./SecondaryCards";
import Summary from "../../../components/new-design/Summary";
import OfferFinalization from "./OfferFinalization";
import Devices from "../../../components/new-design/Devices";
import Personal from "./Personal";
import CustomerTypeSelectionModal from "./CustomerTypeSelectionModal";
import { useNavigationMenuMap } from "../../../hooks/PencilSelling/Navigation/useNavigationMenuMap";
import { useNavigationState } from "../../../hooks/PencilSelling/Navigation/useNavigationState";
import PencilSellingContainer from "../../../components/new-design/PencilSellingContainer";
import { useProfileData } from "../../../hooks/PencilSelling/useProfileData";
import { useRemoveConfiguration } from "../../../hooks/PencilSelling/useRemoveConfiguration";
import { useGetSummaryData } from "../../../hooks/PencilSelling/useGetSummaryData";
import { useGetCustomAddonContractPeriod } from "../../../hooks/PencilSelling/useGetCustomAddonContractPeriod";
import { CUSTOM_DEVICE_FORM_DATA } from "../../../components/new-design/AddonsSelection/const";
import { FactModalKeys } from "../../../core/entities/PencilSelling/FactModal/IFactModal";
// Adds reloading of useEffect, useCallback, useMemo on every injection https://github.com/gaearon/react-hot-loader
setConfig({
  reloadHooks: true,
});

const frameworkContractInitialSelectState = {
  isSelected: false,
  displayErrorMsg: false,
};

const PencilSellingBusiness = ({
  distributionPoint,
  distributionLink,
  magentaAppLink,
  googleReviewLink,
  onlineAppointmentBookingLink,
  user,
}: IPencilSellingProps) => {
  const {
    offerStore,
    configurationsStore,
    productsRepositoryStore,
    productsStore,
    factModalStore,
  } = useStores();
  const customAddonContractPeriod = useGetCustomAddonContractPeriod(
    offerStore.getBaseOffer()
  );
  const { isLoading } = offerStore.getBaseOffer();
  const { customerType } = offerStore.getBaseOffer().customer.get();
  const {
    navigationMenuMap,
    activeStepIndex,
    setActiveStepIndex,
    addConfigurationHandler,
    navigateToStepWithFlyout,
    navigateToStepWithoutFlyout,
    getDefaultConfigurationSettings,
  } = useNavigationMenuMap(configurationsStore, offerStore.getBaseOffer());
  const profileData = useProfileData({
    distributionPoint,
    distributionLink,
    magentaAppLink,
    googleReviewLink,
    onlineAppointmentBookingLink,
    offerStore: offerStore.getBaseOffer(),
  });
  const { getSummaryData } = useGetSummaryData({
    customerType,
    offerStore,
    distributionData: profileData.distributionData,
    bannerLinks: profileData.bannerLinks,
  });
  const navigationState = useNavigationState(NAV_TITLE_BASE_DATA);
  const { removeConfigurationHandler } = useRemoveConfiguration(
    configurationsStore,
    offerStore.getBaseOffer()
  );
  const [customerTypeModalIsActive, setCustomerTypeModalIsActive] =
    useState(true);
  const [frameworkContractSelectState, setFrameworkContractSelectState] =
    useState<IFrameworkContractSelectState>(
      frameworkContractInitialSelectState
    );

  const appResetHandler = useCallback(() => {
    offerStore.getBaseOffer().customer.reset();
    offerStore.configurationsEntity.reset();
    offerStore.getBaseOffer().resetBusinessCases();
    offerStore.resetBusinessGen3CardsData();
    offerStore.setFrameworkContract(null);
    offerStore.setFrameworkContractsList([]);
    setActiveStepIndex(0);
    setFrameworkContractSelectState(frameworkContractInitialSelectState);
    setCustomerTypeModalIsActive(true);
  }, [offerStore, setActiveStepIndex]);

  const offerStepsMap = [
    <Personal
      frameworkContractSelectState={frameworkContractSelectState}
      setFrameworkContractSelectState={setFrameworkContractSelectState}
    />,
    <MobileTariff
      displayConfigurationFlyout={() =>
        navigationState.displayConfigurationFlyout(NAV_TITLE_MOBILE_TARIFF)
      }
    />,
    <SecondaryCards
      displayConfigurationFlyout={() =>
        navigationState.displayConfigurationFlyout(NAV_TITLE_SECONDARY_CARDS)
      }
    />,
    <LandlineTariff
      displayConfigurationFlyout={() =>
        navigationState.displayConfigurationFlyout(NAV_TITLE_LANDLINE_TARIFF)
      }
    />,
    <Devices
      subtitle="Passende Geräte für Festnetz und Internet"
      trackingContext="geräte-festnetz-geschaeftskunden"
      allowCustomProducts
      configurationsStore={configurationsStore}
      offerStore={offerStore.getBaseOffer()}
      productsStore={productsStore}
      customAddonContractPeriod={customAddonContractPeriod}
      customAddonFormData={CUSTOM_DEVICE_FORM_DATA}
    />,
    <OfferFinalization distributionData={profileData.distributionData} />,
    <Summary
      getSummaryData={getSummaryData}
      offerStore={offerStore.getBaseOffer()}
      productsRepositoryStore={productsRepositoryStore}
    />,
  ];

  const navFrameworkContractClickHandler = (
    navItemClickHandler: () => void
  ) => {
    if (frameworkContractSelectState.isSelected) {
      navItemClickHandler();
    } else {
      setFrameworkContractSelectState((prev) => ({
        ...prev,
        displayErrorMsg: true,
      }));
    }
  };

  navigationMenuMap.menu.items = [
    {
      title: NAV_TITLE_BASE_DATA,
      onClick: (navData) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.BASE_DATA);
        navigateToStepWithoutFlyout({ ...navData, stepIndex: 0 });
      },
      getChildren: () => null,
      withArrow: false,
    },
    {
      title: NAV_TITLE_MOBILE_TARIFF,
      onClick: ({
        isActive,
        key,
        setActiveItem,
        setActiveFlyoutKey,
        setBackDropIsActive,
        activeFlyoutKey,
      }) => {
        navFrameworkContractClickHandler(() => {
          factModalStore.setFactModalActiveKey(FactModalKeys.MOBILE_TARIFF);
          navigateToStepWithFlyout({
            isActive,
            key,
            setActiveItem,
            setActiveFlyoutKey,
            setBackDropIsActive,
            activeFlyoutKey,
            stepIndex: 1,
          });
          addConfigurationHandler(ICartConfigurationType.MOBILE);
        });
      },
      getChildren: ({
        key,
        activeNavItem,
        setBackDropIsActive,
        setActiveFlyoutKey,
      }) =>
        key === activeNavItem ? (
          <ConfigurationFlyout
            addConfigurationHandler={() => {
              configurationsStore.addConfiguration(
                getDefaultConfigurationSettings(ICartConfigurationType.MOBILE)
              );
            }}
            removeConfigurationHandler={removeConfigurationHandler}
            configurationsStore={configurationsStore}
            hideFlyoutHandler={() => {
              setBackDropIsActive(false);
              setActiveFlyoutKey(null);
            }}
            type={ICartConfigurationType.MOBILE}
          />
        ) : null,
      withArrow: true,
    },
    {
      title: NAV_TITLE_SECONDARY_CARDS,
      onClick: ({
        isActive,
        key,
        setActiveItem,
        setActiveFlyoutKey,
        setBackDropIsActive,
        activeFlyoutKey,
      }) => {
        navFrameworkContractClickHandler(() => {
          factModalStore.setFactModalActiveKey(FactModalKeys.SECONDARY_CARDS);
          navigateToStepWithFlyout({
            isActive,
            key,
            setActiveItem,
            setActiveFlyoutKey,
            setBackDropIsActive,
            activeFlyoutKey,
            stepIndex: 2,
          });
          addConfigurationHandler(ICartConfigurationType.CARD);
        });
      },
      getChildren: ({
        key,
        activeNavItem,
        setBackDropIsActive,
        setActiveFlyoutKey,
      }) =>
        key === activeNavItem ? (
          <ConfigurationFlyout
            addConfigurationHandler={() =>
              configurationsStore.addConfiguration(
                getDefaultConfigurationSettings(ICartConfigurationType.CARD)
              )
            }
            removeConfigurationHandler={removeConfigurationHandler}
            configurationsStore={configurationsStore}
            hideFlyoutHandler={() => {
              setBackDropIsActive(false);
              setActiveFlyoutKey(null);
            }}
            type={ICartConfigurationType.CARD}
          />
        ) : null,
      withArrow: true,
    },
    {
      title: NAV_TITLE_LANDLINE_TARIFF,
      onClick: ({
        isActive,
        key,
        setActiveItem,
        setActiveFlyoutKey,
        setBackDropIsActive,
        activeFlyoutKey,
      }) => {
        navFrameworkContractClickHandler(() => {
          factModalStore.setFactModalActiveKey(FactModalKeys.LANDLINE_TARIFF);
          navigateToStepWithFlyout({
            isActive,
            key,
            setActiveItem,
            setActiveFlyoutKey,
            setBackDropIsActive,
            activeFlyoutKey,
            stepIndex: 3,
          });
          addConfigurationHandler(ICartConfigurationType.LANDLINE);
        });
      },
      getChildren: ({
        key,
        activeNavItem,
        setBackDropIsActive,
        setActiveFlyoutKey,
      }) =>
        key === activeNavItem ? (
          <ConfigurationFlyout
            addConfigurationHandler={() =>
              configurationsStore.addConfiguration(
                getDefaultConfigurationSettings(ICartConfigurationType.LANDLINE)
              )
            }
            removeConfigurationHandler={removeConfigurationHandler}
            configurationsStore={configurationsStore}
            hideFlyoutHandler={() => {
              setBackDropIsActive(false);
              setActiveFlyoutKey(null);
            }}
            type={ICartConfigurationType.LANDLINE}
          />
        ) : null,
      withArrow: true,
    },
    {
      title: NAV_TITLE_DEVICES,
      onClick: (navData) => {
        navFrameworkContractClickHandler(() => {
          factModalStore.setFactModalActiveKey(FactModalKeys.DEVICES);
          navigateToStepWithoutFlyout({ ...navData, stepIndex: 4 });
          addConfigurationHandler(ICartConfigurationType.DEVICE);
        });
      },
      getChildren: () => null,
      withArrow: false,
    },
    {
      title: NAV_TITLE_OFFER_FINALISATION,
      onClick: (navData) => {
        navFrameworkContractClickHandler(() => {
          factModalStore.setFactModalActiveKey(
            FactModalKeys.OFFER_FINALISATION
          );
          navigateToStepWithoutFlyout({ ...navData, stepIndex: 5 });
        });
      },
      getChildren: () => null,
      withArrow: false,
    },
    {
      title: NAV_TITLE_SUMMARY,
      onClick: (navData) => {
        navFrameworkContractClickHandler(() => {
          factModalStore.setFactModalActiveKey(FactModalKeys.SUMMARY);
          navigateToStepWithoutFlyout({ ...navData, stepIndex: 6 });
        });
      },
      getChildren: () => null,
      withArrow: false,
    },
  ];

  return (
    <LoadingSpinner isLoading={isLoading}>
      <PencilSellingContainer
        mainContainerAriaIsHidden={customerTypeModalIsActive}
        navigationMenuMap={navigationMenuMap}
        navigationState={navigationState}
        appResetHandler={appResetHandler}
        userName={`${user.firstname} ${user.lastname}`}
        configurationsStore={configurationsStore}
        offerStore={offerStore.getBaseOffer()}
        productsRepositoryStore={productsRepositoryStore}
        factModalStore={factModalStore}
      >
        {offerStepsMap[activeStepIndex]}
      </PencilSellingContainer>
      {customerTypeModalIsActive && (
        <CustomerTypeSelectionModal
          setFrameworkContractSelectState={setFrameworkContractSelectState}
          closeModalHandler={() => setCustomerTypeModalIsActive(false)}
        />
      )}
    </LoadingSpinner>
  );
};

export default hot(observer(PencilSellingBusiness));
