import {
  businessCaseOptions,
  BusinessCasesMobile,
  BusinessCasesSecondaryCards
} from "../Tariff/ITariff";

export interface IBusinessCaseDataItems {
  name: string;
  key: BusinessCasesMobile | BusinessCasesSecondaryCards;
}

export interface IBusinessCaseData {
  label: string;
  items: IBusinessCaseDataItems[];
}

export const mobileBusinessCase: IBusinessCaseData = {
  label: "MOBILFUNK",
  items: [
    // TODO Temporary used BusinessCasesMobile enum for business case definition. If some changes come in -> need to create separate enums fro Business Client
    { name: "Mobile-Neukunde", key: BusinessCasesMobile.NEUVERTRAG },
    { name: "Vertragsverlängerung", key: BusinessCasesMobile.VERLAENGERUNG },
  ],
};

export const secondaryCardsBusinessCase: IBusinessCaseData = {
  label: "Zusatzkarten",
  items: [
    // TODO Temporary used BusinessCasesMobile enum for business case definition. If some changes come in -> need to create separate enums fro Business Client
    { name: "Neuvertrag", key: BusinessCasesMobile.NEUVERTRAG },
    {
      name: "Vertragsverlängerung",
      key: BusinessCasesMobile.VERLAENGERUNG,
    },
  ],
};

export enum LandlineBusinessCaseCustomerTypes {
  NEW_CUSTOMER_ITEMS = "newCustomerItems",
  EXISTING_CUSTOMER_ITEMS = "existingCustomerItems",
}

export const landlineBusinessCase = {
  label: "ZUHAUSE",
  alterLabel: "FESTNETZ",
  [LandlineBusinessCaseCustomerTypes.NEW_CUSTOMER_ITEMS]: {
    title: "Festnetz Neukunde",
    items: [businessCaseOptions[0], businessCaseOptions[1]],
  },
  [LandlineBusinessCaseCustomerTypes.EXISTING_CUSTOMER_ITEMS]: {
    title: "Festnetz Bestandskunde",
    items: [
      businessCaseOptions[4],
      businessCaseOptions[2],
      businessCaseOptions[3],
    ],
  },
};
