import React, { useState } from "react";
import { Grid } from "@dtpk-cc/components";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";
import TariffConfigurationExtraSelectionModal from "../../TariffConfigurationExtraSelectionModal";
import { trackClick } from "../../../../../../helpers/reactTracking";
import PhoneItem from "../PhoneItem";
import LinkBtn from "../../../../../../elements/new-design/LinkBtn";
import {
  IAdditionalDevices,
  ICartItemData,
} from "../../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { IStepTrackingContext } from "../../../../../../views/PencilSelling/IPencilSelling";

const PHONES_MODAL_MAX_AMOUNT = 5;

type TariffConfigurationPhonesModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  businessCaseName: string;
  productInCart: ICartItemData | undefined;
  smartphoneInfos: IAdditionalDevices[];
  trackingContext: IStepTrackingContext;
  setSmartphoneInfos: React.Dispatch<
    React.SetStateAction<IAdditionalDevices[]>
  >;
  addSmartphonesHandler: (smartphoneItems: IAdditionalDevices[]) => void;
};

const TariffConfigurationPhonesModal = ({
  isOpen,
  setIsOpen,
  businessCaseName,
  productInCart,
  smartphoneInfos,
  trackingContext,
  setSmartphoneInfos,
  addSmartphonesHandler,
}: TariffConfigurationPhonesModalProps) => {
  const [modalSmartphoneInfoState, setModalSmartphoneInfoState] = useState<
    IAdditionalDevices[]
  >(
    smartphoneInfos.length > 0
      ? smartphoneInfos
      : [{ name: "", price: "", manufacturer: "" }]
  );

  const handleSmartphoneInfoRemove = (phoneItemIndex: number) => {
    setModalSmartphoneInfoState((devicesArr) => {
      const updatedDevicesArr = [...devicesArr];
      updatedDevicesArr.splice(phoneItemIndex, 1);
      return updatedDevicesArr;
    });
  };

  const handleSmartphoneInfoAdd = () => {
    setModalSmartphoneInfoState((devicesArr) => [
      ...devicesArr,
      { name: "", price: "", manufacturer: "" },
    ]);
  };

  const handleSmartphoneInfoChange = (
    index: number,
    key: string,
    value: string
  ) => {
    setModalSmartphoneInfoState((devicesArr) => {
      const updatedDevicesArr = [...devicesArr];
      updatedDevicesArr[index][key] = value;
      return updatedDevicesArr;
    });
  };

  const submitModalHandler = () => {
    const filteredPhones = modalSmartphoneInfoState.filter(
      ({ name, manufacturer }) => !!name.trim() || manufacturer
    );
    addSmartphonesHandler(filteredPhones);
    setSmartphoneInfos(filteredPhones);
    setIsOpen(false);
  };

  return (
    <TariffConfigurationExtraSelectionModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      businessCaseName={businessCaseName}
      tariffName={productInCart?.name}
      onSubmit={() => {
        submitModalHandler();
        trackClick(
          `${trackingContext.portfolio}.modales.smartphone.fenster`,
          trackingContext.main
        );
      }}
      onCancel={() => {
        setIsOpen(false);
        trackClick(
          `${trackingContext.portfolio}.modales.smartphone.schließen`,
          trackingContext.main
        );
      }}
    >
      <Grid>
        {modalSmartphoneInfoState.map((phoneItem, index) => (
          <PhoneItem
            key={index}
            index={index}
            name={phoneItem.name}
            manufacturer={phoneItem.manufacturer}
            price={phoneItem.price}
            onNameClick={() => {
              trackClick(
                `${trackingContext.portfolio}.smartphone.name`,
                trackingContext.main
              );
            }}
            onNameChange={(value: string) =>
              handleSmartphoneInfoChange(index, "name", value)
            }
            onManufacturerChange={(value: string) =>
              handleSmartphoneInfoChange(index, "manufacturer", value)
            }
            onPriceClick={() => {
              trackClick(
                `${trackingContext.portfolio}.smartphone.preis`,
                trackingContext.main
              );
            }}
            onPriceChange={(value: string) =>
              handleSmartphoneInfoChange(index, "price", value)
            }
            onRemove={() => handleSmartphoneInfoRemove(index)}
          />
        ))}
      </Grid>
      {modalSmartphoneInfoState.length < PHONES_MODAL_MAX_AMOUNT && (
        <LinkBtn
          icon={ActionAddDefault}
          onClick={() => {
            handleSmartphoneInfoAdd();
            trackClick(
              `${trackingContext.portfolio}.smartphone.hinzufügen`,
              trackingContext.main
            );
          }}
          text="Endgerät hinzufügen"
        />
      )}
    </TariffConfigurationExtraSelectionModal>
  );
};

export default TariffConfigurationPhonesModal;
