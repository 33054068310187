import { Group, PortfolioKeys } from "../core/entities/Product/IProduct";
import { CardsPresenter } from "../core/presenter/PencilSelling/CardsPresenter";
import {
  IAdditionalDevices,
  ICartItemData,
  ICartItemDataPromotions,
  IMultiSim,
  PaymentTypes,
} from "../core/entities/PencilSelling/CartItem/ICartItem";
import { ICard } from "../core/entities/Product/Card/ICard";
import { ICardSettings as LegacyCardSettings } from "../core/entities/PencilSelling/Offer_legacy/IOffer";
import { MULTISIM_PRICE } from "../components/CardsPortfolio";
import { ICardSettings } from "../core/entities/PencilSelling/Configurations/IConfigurations";

interface IGetSecondaryCardDataForCart {
  card: ICard;
  cardSettings: ICardSettings | Partial<LegacyCardSettings>;
  additionalDevices: IAdditionalDevices[];
  promotions: ICartItemDataPromotions;
  note: string;
  multiSim?: IMultiSim;
  businessCaseKey: string;
}

export const getSecondaryCardDataForCart = ({
  card,
  additionalDevices,
  promotions,
  note,
  multiSim = {
    price: parseFloat(MULTISIM_PRICE.replace(",", ".")),
    quantity: 0,
  },
  businessCaseKey,
  cardSettings,
}: IGetSecondaryCardDataForCart): ICartItemData => {
  const dataVolume = card.dataVolume || cardSettings.inheritedDataVolume;
  const { dataVolumeMagentaOne } = card;

  return {
    name: card.name,
    price: card.price,
    key: card.key,
    quantity: 1,
    portfolio: PortfolioKeys.MOBILE,
    group: Group.card,
    description: {
      summary: card.inheritingCard
        ? CardsPresenter.getCardSummary(
            card.description?.summary,
            cardSettings.tariff?.dataVolume,
            cardSettings.tariff?.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          )
        : CardsPresenter.getStaticCardSummary(
            card.description?.summary,
            card.dataVolume,
            card.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          ),
      text: card.inheritingCard
        ? CardsPresenter.getCardDescription(
            card.description?.text,
            cardSettings.tariff?.size,
            cardSettings.tariff?.dataVolume,
            cardSettings.tariff?.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          )
        : CardsPresenter.getStaticCardDescription(
            card.description?.text,
            card.dataVolume,
            card.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          ),
    },
    contractPeriod: card.contractPeriod,
    promotions,
    paymentType: PaymentTypes.MONTHLY,
    additionalDevices,
    isFungible: false,
    cardData: {
      note,
      multiSim,
      level: card.level,
      dataVolume,
      dataVolumeMagentaOne,
      size: card.size,
      businessCaseKey,
    },
    inheritingCard: card.inheritingCard,
    tariffWorld: card.tariffWorld,
  };
};
