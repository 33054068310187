// extracted by mini-css-extract-plugin
var _1 = "addon-item-module-active-Y2Tab";
var _2 = "addon-item-module-addonItem-RmkSh";
var _3 = "addon-item-module-badge-EFXj5";
var _4 = "addon-item-module-content-f8HJ3";
var _5 = "addon-item-module-footer-cYBQx";
var _6 = "addon-item-module-header-zFw_I";
var _7 = "addon-item-module-mainPriceWrapper-kyBcg";
var _8 = "addon-item-module-priceMainContainer-U3Rc9";
var _9 = "addon-item-module-promotionSelectionBtn-IwnfI";
var _a = "addon-item-module-promotionSelectionBtnIcon-xDQ8r";
var _b = "addon-item-module-secondaryPriceWrapper-kNomT";
var _c = "addon-item-module-title-mlbPg";
var _d = "addon-item-module-withoutMonthly-UWQ59";
export { _1 as "active", _2 as "addonItem", _3 as "badge", _4 as "content", _5 as "footer", _6 as "header", _7 as "mainPriceWrapper", _8 as "priceMainContainer", _9 as "promotionSelectionBtn", _a as "promotionSelectionBtnIcon", _b as "secondaryPriceWrapper", _c as "title", _d as "withoutMonthly" }
