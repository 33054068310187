import React from "react";
import { Heading, CopyText } from "@dtpk-cc/components";
import ModalWrapper from "../../../../elements/new-design/ModalWrapper";
import ModalWrapperActionFooter from "../../../../elements/new-design/ModalWrapper/ModalWrapperActionFooter";
import * as styles from "./addons-selection-modal-wrapper.module.scss";


type AddonsSelectionModalWrapperProps = {
  onSubmit: () => void;
  onCancel: () => void;
  title: string;
  isOpen: boolean;
  subtitle?: string;
  customClass?: string;
  submitDisabled?: boolean;
};

const AddonsSelectionModalWrapper: React.FC<
  AddonsSelectionModalWrapperProps
> = ({
  subtitle = "Konditionsauswahl",
  isOpen,
  onSubmit,
  onCancel,
  title,
  children,
  customClass = "",
  submitDisabled = false,
}) => (
  <ModalWrapper containerCustomClass={customClass} isOpen={isOpen} onClose={onCancel} onBackDropClick={onCancel}>
    <div className={styles.header}>
      <Heading
        customClass="m-b-0"
        variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      >
        {title}
      </Heading>
      <CopyText>{subtitle}</CopyText>
    </div>
    {children}
    <ModalWrapperActionFooter submitDisabled={submitDisabled} onSubmit={onSubmit} onCancel={onCancel} />
  </ModalWrapper>
);

export default AddonsSelectionModalWrapper;
