import { ITariff } from "core/entities/Product/Tariff/ITariff";

export const getBenefitDiscount = (tariff: ITariff) => {
  const rebateOption = tariff.benefits[0]?.options.find(
    (option) => option?.key === "discount"
  );

  return parseFloat(`${rebateOption?.value || 0}`);
};

export const getDefaultTariffPrice = (
  tariff: ITariff,
  isBenefitActive: boolean
): number => {
  const currentPrice =
    (tariff.isYoung &&
      typeof tariff.price.youngDiscount?.price === "number" &&
      tariff.price.youngDiscount?.price) ||
    tariff.price.monthly;
  return isBenefitActive
    ? currentPrice - getBenefitDiscount(tariff)
    : currentPrice;
};
