import React from "react";
import { List } from "@dtpk-cc/components";
import ContentClockDefault from "@dtpk-cc/components/dist/icons/Content/Clock/Default";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import SummarySectionOptionsTitle from "../SummarySectionOptionsTitle";
import { HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION } from "../../../../constants/const";
import { IHappyHourBenefitOptionDescription } from "../../../../views/PencilSelling/IPencilSelling";

type SummaryHappyHourProps = {
  customClass?: string;
  happyHourBenefitOptionDescription: IHappyHourBenefitOptionDescription;
};

const SummaryHappyHour = ({
  customClass = "",
  happyHourBenefitOptionDescription,
}: SummaryHappyHourProps) => (
  <>
    <SummarySectionItemWrapper
      variant="borderedInSection"
      customClass={customClass}
    >
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <SummarySectionOptionsTitle
          title={happyHourBenefitOptionDescription.title}
          fontColor="magenta"
          Icon={ContentClockDefault}
        />
      </SummarySectionItemWrapper.Main>
      <SummarySectionItemWrapper.Monthly />
      <SummarySectionItemWrapper.Once />
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <List customClass="m-l-36 m-r-12">
          <List.Item
            dangerouslySetInnerHTML={{
              __html: HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION.text,
            }}
          />
        </List>
      </SummarySectionItemWrapper.Main>
    </SummarySectionItemWrapper>
  </>
);

export default SummaryHappyHour;
