import React, { Fragment, useState } from "react";
import { Button, Icon } from "@dtpk-cc/components";
import EditConfigurationIcon from "../../../../assets/images/icons/pencil-selling-tool/tariff-selection/edit.svg";
import { trackClick } from "../../../helpers/reactTracking";
import * as styles from "./rename-configuration-modal.module.scss";
import { ConfigurationsEntity } from "../../../core/entities/PencilSelling/Configurations/Configurations";
import TextInputModal from "../TextInputModal";

type RenameConfigurationModalProps = {
  trackingContext: string;
  configurationsStore: ConfigurationsEntity;
};

const RenameConfigurationModal: React.FC<RenameConfigurationModalProps> = ({
  trackingContext,
  configurationsStore,
}) => {
  const activeConfiguration = configurationsStore.getActiveConfiguration();
  const [renameConfigModalIsOpen, setRenameConfigModalIsOpen] = useState(false);

  const closeRenameConfigurationModalHandler = () =>
    setRenameConfigModalIsOpen(false);

  const submitConfigurationRenameHandler = (inputValue: string) => {
    closeRenameConfigurationModalHandler();
    configurationsStore.setConfigurationName(
      activeConfiguration.key,
      inputValue
    );
    trackClick(`${trackingContext}.konfiguration-umbenennen`);
  };

  return (
    <Fragment key={`${renameConfigModalIsOpen.toString()}`}>
      <Button
        customClass={styles.renameConfigActionBtn}
        variants={Button.Variant.bare}
        onClick={() => setRenameConfigModalIsOpen(true)}
      >
        <Icon
          wrapperProps={{ className: styles.renameIcon }}
          icon={EditConfigurationIcon}
        />
      </Button>
      <TextInputModal
        title="Ändern des Konfigurationsnamens"
        inputPlaceholder="Konfigurationsname"
        onSubmit={submitConfigurationRenameHandler}
        onCancel={closeRenameConfigurationModalHandler}
        onInputClick={() => {
          trackClick(`${trackingContext}.konfigurationsname`);
        }}
        defaultInputValue={activeConfiguration.name}
        isOpen={renameConfigModalIsOpen}
      />
    </Fragment>
  );
};

export default RenameConfigurationModal;
