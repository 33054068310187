import { useCallback, useMemo } from "react";
import { MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY } from "constants/const";
import { useStores } from "../../../stores";
import { useAddTariffPromotionToOffer } from "../useAddTariffPromotionToOffer";
import { useAddSecondaryCardToCart } from "../useAddSecondaryCardToCart";
import { PortfolioKeys } from "../../../core/entities/Product/IProduct";
import {
  IAdditionalDevices,
  PaymentTypes,
} from "../../../core/entities/PencilSelling/CartItem/ICartItem";
import { TariffWorldKeys } from "../../../core/repositories/ProductsRepository/DefinitionKeys";
import { MULTISIM_PRICE } from "../../../components/CardsPortfolio";
import { PromotionPriceType } from "../../../core/entities/Product/IDiscount";
import { PersonalDataPresenter } from "../../../core/presenter/PencilSelling/PersonalDataPresenter";
import { ICard } from "../../../core/entities/Product/Card/ICard";
import { IBenefitsData } from "../../../core/entities/MagentaNext/Tariff/ITariff";
import { ITariff as ITariffProduct } from "../../../core/entities/Product/Tariff/ITariff";
import { TariffPresenter } from "../../../core/presenter/PencilSelling/TariffPresenter";
import {
  CustomPromotionTypes,
  ICustomPromotion,
} from "../../../core/entities/PencilSelling/ICustomPromotion";

interface ISecondaryCardsItem extends ICard {
  additionalDevices: IAdditionalDevices[];
}

interface IMagentaCalculatorConfig {
  hasProvisionFee: boolean;
  loyaltyBonus: number;
  mainCard: ITariffProduct;
  secondaryCards: ISecondaryCardsItem[];
  benefits: IBenefitsData;
  smartphoneName: string;
  smartphonePrice: number;
  promotions: ICustomPromotion[];
}

export const useApplyMagentaCalculatorConfig = () => {
  const { offerStore, cartStore, productsStore } = useStores();
  const { addPromotion } = useAddTariffPromotionToOffer(offerStore);
  const { addSecondaryCardToCart } = useAddSecondaryCardToCart();
  const personalDataPresenter = useMemo(
    () => new PersonalDataPresenter(false),
    []
  );
  const businessCases = useMemo(
    () => personalDataPresenter.mobileBusinessCase.items,
    [personalDataPresenter]
  );

  const applyMagentaCalculatorConfig = useCallback(() => {
    const magentaCalculatorConfig = localStorage.getItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY
    );

    if (!magentaCalculatorConfig) return;

    const {
      hasProvisionFee,
      loyaltyBonus,
      mainCard,
      secondaryCards,
      benefits,
      smartphoneName,
      smartphonePrice,
      promotions,
    } = JSON.parse(magentaCalculatorConfig) as IMagentaCalculatorConfig;
    // Gather useCases to apply all part of configuration
    const tariffSectionConfigApplyHandler = () => {
      const {
        quantity,
        additionalDescription,
        contractPeriod,
        description,
        type,
        key,
        name,
        price,
        tariffWorld,
        dataVolume,
        dataVolumeMagentaOne,
      } = mainCard;
      const currentMobileSettings = offerStore.getMobileSettings();

      // Convert product from calculator to ICartItemData type.
      cartStore.addToCart({
        quantity,
        additionalDescription,
        contractPeriod,
        key,
        name,
        price,
        tariffWorld,
        description: {
          summary: TariffPresenter.getMobileTariffSummary(
            description.summary,
            dataVolume,
            dataVolumeMagentaOne,
            benefits.isActive
          ),
          text: TariffPresenter.getMobileTariffDescription(
            description.text,
            dataVolume,
            dataVolumeMagentaOne,
            benefits.isActive
          ),
        },
        promotions: [],
        portfolio: PortfolioKeys.MOBILE,
        paymentType: PaymentTypes.MONTHLY,
        isFungible: false,
        group: type,
        additionalDevices: [
          {
            name: smartphoneName,
            price: smartphonePrice ? `${smartphonePrice}` : "",
          },
        ],
      });
      offerStore.setMobileSettings({
        ...currentMobileSettings,
        hasProvisionFee,
        loyaltyBonusValue: loyaltyBonus,
        portfolio: PortfolioKeys.MOBILE,
        tariffWorld: TariffWorldKeys.magenta_mobile,
        isYoung: false,
        level: mainCard.level.key,
      });
      offerStore.setTariffBenefits({
        ...offerStore.getTariffBenefits(),
        isActive: benefits.isActive,
      });
    };

    const secondaryCardsConfigApplyHandler = () => {
      const currentCardSettings = offerStore.getCardSettings();
      const mobileTariffs = productsStore.tariffs[PortfolioKeys.MOBILE];
      const targetTariff = mobileTariffs.find(
        (tariff) => tariff.key === mainCard.key
      );

      offerStore.setCardSettings({
        ...currentCardSettings,
        benefitIsActive: benefits.isActive,
        isYoung: false,
        level: targetTariff.level.key,
        tariff: targetTariff,
        // Magenta calculator works only with 'magenta_mobile' tariff world 22.03.23
        tariffWorld: TariffWorldKeys.magenta_mobile,
      });
      secondaryCards.forEach((card) => {
        addSecondaryCardToCart({
          card,
          promotions: [],
          additionalDevices: card.additionalDevices,
          note: "",
          multiSim: {
            price: parseFloat(MULTISIM_PRICE.replace(",", ".")),
            quantity: 0,
          },
          businessCaseKey: businessCases[0].key,
          cardSettings: currentCardSettings,
          cardKey: card.key,
        });
      });
    };

    const tariffPromotionsApplyHandler = () => {
      const mobileTariff = cartStore.getMobileTariff();
      promotions.forEach((promotion) => {
        const promotionValue = promotion.discount?.value || 0;
        // TODO set up defalut from -> to in case for Monthly promotion type
        // TODO set default from as 1
        const promotionTypeDefineMap = [
          {
            check: () => promotion.contractPeriod <= 0 && promotionValue <= 0,
            promotionType: CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE,
          },
          {
            check: () => promotion.contractPeriod <= 0 && promotionValue > 0,
            promotionType: CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE,
          },
          {
            check: () =>
              (promotion.contractPeriod > 0 && promotionValue > 0) ||
              // This configuration is also treated as 'monthly' because we have contractPeriod > 0
              (promotion.contractPeriod > 0 && promotionValue === 0),
            promotionType: CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE,
          },
        ];

        const [{ promotionType }] = promotionTypeDefineMap.filter((item) =>
          item.check()
        );

        const promotionItem = {
          isEditable: false,
          name: promotion.name,
          description: promotion.name,
          key: `${promotion.key}`,
          portfolio: PortfolioKeys.MOBILE,
          type: promotionType,
          discount: {
            value:
              promotionType ===
              CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE
                ? null
                : promotionValue,
            interval:
              promotionType ===
              CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE
                ? null
                : promotion.contractPeriod,
            kind: PromotionPriceType.CUSTOM_PROMOTION,
            description: null,
            from: null,
            to: null,
          },
        };
        addPromotion(promotionItem, PortfolioKeys.MOBILE);
        mobileTariff.promotions.push(promotionItem);
      });
    };

    tariffSectionConfigApplyHandler();
    secondaryCardsConfigApplyHandler();
    tariffPromotionsApplyHandler();

    //  Remove config from localStorage
    localStorage.removeItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY
    );
  }, [
    offerStore,
    cartStore,
    productsStore,
    addPromotion,
    addSecondaryCardToCart,
    businessCases,
  ]);

  return { applyMagentaCalculatorConfig };
};
