import { useCallback } from "react";
import {
  IAdditionalDevices,
  IMultiSim,
} from "core/entities/PencilSelling/CartItem/ICartItem";
import { ICard } from "../../core/entities/Product/Card/ICard";
import { IPromotion } from "../../core/entities/PencilSelling/IPromotion";
import { ICardSettings } from "../../core/entities/PencilSelling/Offer_legacy/IOffer";
import { useStores } from "../../stores";
import { getSecondaryCardDataForCart } from "../../helpers/GetSecondaryCardDataForCart";

type IAddCardHandlerProps = {
  card: ICard;
  cardSettings: ICardSettings;
  additionalDevices: IAdditionalDevices[];
  promotions: IPromotion[];
  note: string;
  multiSim: IMultiSim;
  businessCaseKey: string;
  cardKey: string;
};

export const useAddSecondaryCardToCart = () => {
  const { cartStore } = useStores();

  const addSecondaryCardToCart = useCallback(
    ({
      card,
      additionalDevices,
      promotions,
      note,
      multiSim,
      businessCaseKey,
      cardSettings,
      cardKey,
    }: IAddCardHandlerProps) => {
      const cartItem = getSecondaryCardDataForCart({
        card,
        additionalDevices,
        promotions,
        note,
        multiSim,
        businessCaseKey,
        cardSettings,
      });

      const cardInCart = cartStore.find(card.key);
      if (cardInCart) {
        cartStore.replace(cartItem.key, cartItem);
      } else {
        cartItem.key = cardKey;
        cartStore.addToCart(cartItem);
      }
    },
    [cartStore]
  );

  return { addSecondaryCardToCart };
};
