import React from "react";
import { observer } from "mobx-react";
import AddonsSelection from "../../../../../components/new-design/AddonsSelection";
import { PropsWithBusinessCase } from "../../../BusinessClient/types";
import {
  Group,
  PortfolioKeys,
} from "../../../../../core/entities/Product/IProduct";
import { getBusinessCaseName } from "../../../../../helpers/GetBusinessCaseName";
import { mobileBusinessCase } from "../../../../../core/entities/Product/BusinessClient/IBaseData";
import { useStores } from "../../../../../stores/PrivateClient";
import { useGetCustomAddonContractPeriod } from "../../../../../hooks/PencilSelling/useGetCustomAddonContractPeriod";
import { CUSTOM_EXTRA_FORM_DATA } from "../../../../../components/new-design/AddonsSelection/const";
import { tariffProductDescriptionMap } from "../../../../../helpers/TariffSelection";
import { getRelatedAddonsAreSelected } from "../../../../../helpers/GetRelatedAddonsAreSelected";

export interface MobileTariffAddonsProps extends PropsWithBusinessCase {
  trackingContext?: string;
}

const MobileTariffAddons = ({ businessCase }: MobileTariffAddonsProps) => {
  const { configurationsStore, offerStore, productsStore } = useStores();
  const addons = productsStore.getMobileOptions();
  const addonsInCart = configurationsStore
    .getActiveConfigurationChildren()
    .getMobileOptions();
  const activeTariffInCart = configurationsStore
    .getActiveConfigurationChildren()
    .getMobileTariff();
  const baseTariffProduct = productsStore
    .getMobileTariffs()
    .find((tariff) => tariff.key === activeTariffInCart.key);
  const customAddonContractPeriod = useGetCustomAddonContractPeriod(
    offerStore.getBaseOffer()
  );

  const updateActiveTariffDescription = () => {
    const relatedAddonsAreSelected = getRelatedAddonsAreSelected(
      baseTariffProduct,
      configurationsStore
    );

    const isBenefitActive =
      configurationsStore.getActiveConfigurationBenefitIsActive();
    const tariffUpdatedDescription = tariffProductDescriptionMap[
      PortfolioKeys.MOBILE
    ]({
      tariff: baseTariffProduct,
      isBenefitActive,
      relatedAddonsAreSelected,
    });
    configurationsStore
      .getActiveConfigurationChildren()
      .replace(activeTariffInCart.key, {
        ...activeTariffInCart,
        description: tariffUpdatedDescription,
      });
  };

  return (
    <AddonsSelection
      isFungible
      key={addons.length}
      updateRelatedTariff={updateActiveTariffDescription}
      products={addons}
      activeProducts={addonsInCart}
      allowCustomProducts={true}
      condition={activeTariffInCart.key}
      portfolio={PortfolioKeys.MOBILE}
      addonGroup={Group.addon}
      trackingContext="extras-mobilfunk-privatkunden"
      configurationsStore={configurationsStore}
      businessCaseName={getBusinessCaseName(
        businessCase,
        mobileBusinessCase.items
      )}
      customAddonContractPeriod={customAddonContractPeriod}
      customAddonFormData={CUSTOM_EXTRA_FORM_DATA}
    />
  );
};

export default observer(MobileTariffAddons);
