import React from "react";
import { CopyText, Grid, Price } from "@dtpk-cc/components";
import * as stylesExtra from "../extra-selection.module.scss";
import RadioButtonInput from "../../RadioButtonMain/RadioButtonInput";
import { IPrice } from "../../../../core/entities/Product/IPrice";

type ProductVariantSelectionItemProps = {
  name: string;
  id: string;
  checked: boolean;
  onChange: (value) => void;
  title: string;
  subtitle: string;
  price: IPrice | null;
  additionalPriceSuffix: string;
  inputTestId?: string;
  value?: string;
};

const ProductVariantSelectionItem = ({
  name,
  id,
  checked,
  onChange,
  title,
  subtitle,
  price,
  additionalPriceSuffix,
  value = "",
  inputTestId = null,
}: ProductVariantSelectionItemProps) => {
  const priceSuffix =
    typeof price?.monthly === "number"
      ? `mtl. ${additionalPriceSuffix}`
      : "einmalig";
  const currentPrice =
    typeof price?.monthly === "number" ? price.monthly : price.once;
  return (
    <label className={stylesExtra.extraSelectionLabelWrapper} htmlFor={id}>
      <Grid.Row
        customClass={`${stylesExtra.extraSelection} ${
          checked ? stylesExtra.active : ""
        }`}
      >
        <Grid.Col
          customClass={stylesExtra.extraInputWrapper}
          m={1}
          l={1}
          xl={1}
        >
          <RadioButtonInput
            value={value}
            name={name}
            id={id}
            inputTestId={inputTestId}
            checked={checked}
            onChange={onChange}
          />
        </Grid.Col>
        <Grid.Col
          customClass={`${stylesExtra.descriptionWrapper} p-l-6`}
          m={8}
          l={8}
          xl={17}
        >
          <CopyText customClass={stylesExtra.extraTitle}>{title}</CopyText>
          <CopyText variants={CopyText.Variant.additional}>{subtitle}</CopyText>
        </Grid.Col>
        <Grid.Col customClass={stylesExtra.currentPrice} m={3} l={3} xl={6}>
          <Price
            value={currentPrice}
            suffix={priceSuffix}
            variants={[Price.Variant.brand, Price.Variant.large]}
          />
        </Grid.Col>
      </Grid.Row>
    </label>
  );
};

export default ProductVariantSelectionItem;
