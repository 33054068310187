import React, { useState } from "react";
import { observer } from "mobx-react";
import {
  IAdditionalDevices,
  ICartItemData,
} from "core/entities/PencilSelling/CartItem/ICartItem";
import { IStepTrackingContext } from "views/PencilSelling/IPencilSelling";
import { trackClick } from "helpers/reactTracking";
import AddDeviceIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/add-device.svg";
import * as stylesTariffConfiguration from "../BaseMobileTariffConfiguration/tariff-configuration.module.scss";
import TariffConfigurationAddons from "../TariffConfigurationAddons";
import TariffConfigurationPhonesModal from "./TariffConfigurationPhonesModal";
import TariffConfigurationTileButton from "../TariffConfigurationTileButton";
import { getSmartphonesName } from "../../../../../helpers/GetSmartphonesName";

type TariffConfigurationPhonesProps = {
  smartphoneInfos: IAdditionalDevices[];
  setSmartphoneInfos: React.Dispatch<
    React.SetStateAction<IAdditionalDevices[]>
  >;
  trackingContext: IStepTrackingContext;
  customClass?: string;
  tariffInCart: ICartItemData | undefined;
  businessCaseName: string;
  maxItemsDisplayAmount: number;
  addSmartphonesHandler: (smartphoneItems: IAdditionalDevices[]) => void;
};

const TariffConfigurationPhones = ({
  trackingContext,
  customClass = "",
  tariffInCart,
  businessCaseName,
  smartphoneInfos,
  setSmartphoneInfos,
  maxItemsDisplayAmount,
  addSmartphonesHandler,
}: TariffConfigurationPhonesProps) => {
  const [phonesModalIsOpen, setPhonesModalIsOpen] = useState(false);

  const smartPhoneOpenModalHandler = () => {
    setPhonesModalIsOpen(true);
    trackClick(
      `${trackingContext.portfolio}.modales.smartphone.geöffnet`,
      trackingContext.main
    );
  };

  const phoneNamesArr = getSmartphonesName(smartphoneInfos);

  return (
    <div
      className={`${stylesTariffConfiguration.addonsWrapper} ${customClass}`}
    >
      <TariffConfigurationTileButton
        customClass={stylesTariffConfiguration.addonsTileButton}
        Icon={AddDeviceIcon}
        title="Endgerät hinzufügen"
        subtitle="Smartphones, Tablets, Smartwatches etc."
        onClick={smartPhoneOpenModalHandler}
      />
      <TariffConfigurationAddons
        maxItemsDisplayAmount={maxItemsDisplayAmount}
        addons={phoneNamesArr}
        actionBtnText="Alle Endgeräte anzeigen"
        openModalHandler={smartPhoneOpenModalHandler}
      />
      {phonesModalIsOpen && (
        <TariffConfigurationPhonesModal
          isOpen={phonesModalIsOpen}
          setIsOpen={setPhonesModalIsOpen}
          businessCaseName={businessCaseName}
          productInCart={tariffInCart}
          smartphoneInfos={smartphoneInfos}
          trackingContext={trackingContext}
          setSmartphoneInfos={setSmartphoneInfos}
          addSmartphonesHandler={addSmartphonesHandler}
        />
      )}
    </div>
  );
};

export default observer(TariffConfigurationPhones);
