export const CUSTOM_EXTRA_FORM_DATA = {
  title: "Weiteres Extra hinzufügen",
  namePlaceHolder: "Name des Extras",
  trackClickValue: "extras-name",
};

export const CUSTOM_DEVICE_FORM_DATA = {
  title: "Weiteres Gerät hinzufügen",
  namePlaceHolder: "Gerätename",
  trackClickValue: "geräte-name",
};